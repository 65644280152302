<template>
    <v-row class="mt_dec--30">
        <!-- Start Blog Area  -->
        <v-col
            lg="4"
            md="4"
            sm="6"
            cols="12"
            v-for="(item, i) in $store.state.predicas.data"
            :key="i"
        >
            <div class="im_box mt--30 relative">
                <div class="d-flex justify-end poss_absolute w-100">
                    <v-btn
                        v-if="$store.state.admin.Admin == true"
                        color="red lighten-2"
                        class="mr-2"
                        @click="OpenDialog(item)"
                    >
                        Borrar
                    </v-btn>
                    <v-btn
                        v-if="$store.state.admin.Admin == true"
                        color="success"
                        @click="OpenDialogEdit(item)"
                    >
                        Editar
                    </v-btn>
                </div>
                <div class="thumbnail">
                    <img class="w-100" :src="item.Foto" alt="Blog Images" />
                </div>
                <div class="content">
                    <div class="inner">
                        <div class="content_heading">
                            <h4 class="heading-title">
                                <router-link
                                    :to="
                                        `/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_4}/${item.EventoID}`
                                    "
                                    >{{ item.Nombre }}</router-link
                                >
                            </h4>
                        </div>
                        <div class="content_footer">
                            <router-link
                                :to="
                                    `/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_4}/${item.EventoID}`
                                "
                                class="rn-btn btn-opacity"
                            >
                                Ver mas
                            </router-link>
                        </div>
                    </div>
                    <!-- <router-link
            class="transparent_link"
            to="/blog-details"
          ></router-link> -->
                </div>
            </div>
        </v-col>
        <!-- End Blog Area  -->
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Borrar <b class="ml-2">{{ borrando.Nombre }}</b>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="
                            $store
                                .dispatch('delete_Predica', borrando)
                                .then(() => {
                                    dialog = false;
                                    $store.dispatch('get_Predicas');
                                })
                        "
                    >
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="edit" max-width="800">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Editar <b class="ml-2">{{ borrando.Nombre }}</b>
                </v-card-title>

                <v-card-text class="mt-5">
                    <v-text-field
                        name="name"
                        label="Nombre"
                        id="id"
                        v-model="borrando.Nombre"
                        outlined
                    ></v-text-field>
                    
                    <v-text-field
                        outlined
                        label="Fecha Inicial"
                        type="datetime-local"
                        v-model="borrando.Fecha"
                    >
                        <template v-slot:prepend-inner>
                            <v-checkbox
                                v-model="borrando.useFecha"
                                style="margin:0;padding:0"
                            />
                        </template>
                    </v-text-field>
                    <v-text-field
                        outlined
                        label="Fecha Finalizado"
                        type="datetime-local"
                        v-model="borrando.Fecha_Finalizado"
                    >
                        <template v-slot:prepend-inner>
                            <v-checkbox
                                v-model="borrando.useFecha_Finalizado"
                                style="margin:0;padding:0"
                            />
                        </template>
                    </v-text-field>
                    <v-checkbox label="Agendar" class="my-n5" v-model="borrando.Agendar" true-value="1" false-value="0" />
                    <v-file-input @change="(e) => preview(e)" />
                    <img style="width:160px;" :src="borrando.Foto" alt="" />
                    <v-text-field
                        name="name"
                        label="Video"
                        id="id"
                        v-model="borrando.Video"
                        outlined
                        clearable
                    ></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="edit = false">
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="
                            $store
                                .dispatch('update_Predica', borrando)
                                .then(() => {
                                    edit = false;
                                    $store.dispatch('get_Predicas');
                                    $store.dispatch('get_Last_Predica');
                                })
                        "
                    >
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import moment from "moment";
import blogedit from "../blogeditoredit/index.vue";

export default {
    components: {
        blogedit,
    },
    data() {
        return {
            dialog: false,
            edit: false,
            blogContent: [
                {
                    src: require("../../assets/images/blog/blog-01.jpg"),
                    title: "What is the Difference between Web and Brand.",
                    category: "App Development",
                },
                {
                    src: require("../../assets/images/blog/blog-02.jpg"),
                    title:
                        "A big ticket gone to be an interesting look New York.",
                    category: "React App",
                },
                {
                    src: require("../../assets/images/blog/blog-03.jpg"),
                    title:
                        "Getting tickets to the big show have a closer look.",
                    category: "Photoshop",
                },
                {
                    src: require("../../assets/images/blog/blog-04.jpg"),
                    title: "What is the Difference between Web and Brand.",
                    category: "App Development",
                },
                {
                    src: require("../../assets/images/blog/blog-05.jpg"),
                    title:
                        "A big ticket gone to be an interesting look New York.",
                    category: "React App",
                },
                {
                    src: require("../../assets/images/blog/blog-06.jpg"),
                    title:
                        "Getting tickets to the big show have a closer look.",
                    category: "Photoshop",
                },
            ],
            borrando: {},
        };
    },
    methods: {
        OpenDialog(data) {
            this.dialog = true;
            this.borrando = { ...data };
        },
        OpenDialogEdit(data) {
            this.edit = true;
            this.borrando = {
                ...data,
                useFecha: true,
                Fecha: moment(data.Fecha).format("yyyy-MM-DDThh:mm"),
                useFecha_Finalizado: true,
                Fecha_Finalizado: moment(data.Fecha_Finalizado).format(
                    "yyyy-MM-DDThh:mm"
                ),
            };
        },
        preview(e) {
            var reader = new FileReader();
            reader.addEventListener("load", (readFile) => {
                console.log(reader.result);
                this.borrando.Foto = reader.result;
            });
            reader.readAsDataURL(e);
        },
    },
};
</script>
