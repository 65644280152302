<template>
    <span>
        <v-btn
            v-if="$store.state.admin.Admin == 1"
            color="success"
            @click="OpenDialog()"
            >Agregar</v-btn
        >
        <v-dialog v-model="dialog" width="800">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Crear Articulo
                </v-card-title>

                <v-card-text class="mt-5 d-flex align-items-center ">
                    <label for="Foto">
                        <v-img
                            :src="articulo.Foto"
                            max-width="200"
                            max-height="200"
                        >
                        </v-img>
                        <input
                            type="file"
                            style="display:none"
                            id="Foto"
                            @change="(e) => preview(e)"
                        />
                    </label>

                    <div style="width:100%" class="ml-5">
                        <v-text-field
                            v-model="articulo.Titulo"
                            label="Titulo"
                            outlined
                        ></v-text-field>
                        <v-text-field
                            v-model="articulo.Autor"
                            label="Autor"
                            outlined
                        ></v-text-field>
                    </div>
                </v-card-text>
                <v-card-text>
                    <shareEditor />
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Cerrar
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="
                            $store
                                .dispatch('create_Articulo', articulo)
                                .then(() => {
                                    $store.dispatch('get_Articulos');
                                    dialog = false;
                                })
                        "
                    >
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import shareEditor from "../shareEditor";
export default {
    components: {
        shareEditor,
    },
    data() {
        return {
            dialog: false,
            articulo: {
                Foto: require("../../assets/images/galery.png"),
                Titulo: "",
                Autor: "",
            },
        };
    },
    methods: {
        preview(e) {
            try {
                console.log(e);
                let filereader = new FileReader();
                filereader.onload = () => {
                    this.articulo.Foto = filereader.result;
                };

                filereader.readAsDataURL(e.target.files[0]);
            } catch (e) {}
        },
        OpenDialog(){
            this.$store.state.editorHtml = null
            this.articulo = {
                Foto: require("../../assets/images/galery.png"),
                Titulo: "",
                Autor: "",
            }
            this.dialog = true
        }
    },
};
</script>

<style></style>
