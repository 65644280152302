<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" :src="logo" />
        </Header>
        <!-- End Header Area -->

        <!-- Start Breadcrump Area  -->
        <div
            class="rn-page-title-area pt--120 pb--190 bg_image bg_image--27"
            data-black-overlay="7"
            :style="`background-image: url(${articulo.Foto})`"
        >
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-center blog-single-page-title pt--100">
                            <h2 class="heading-title theme-gradient">
                                {{ articulo.Nombre_Ministerio }}
                            </h2>
                            <!-- <ul
                                class="justify-center blog-meta d-flex align-center"
                            >
                                <li
                                    class="d-flex"
                                    v-for="(meta, i) in metaList"
                                    :key="i"
                                >
                                    <span v-html="iconSvg(meta.icon)"></span
                                    >{{ meta.text }}
                                </li>
                            </ul> -->
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div
            class="d-flex justify-center mt-3"
            v-if="$store.state.admin.Admin == 1"
        >
            <v-btn color="success" @click="dialog = true"
                >Agregar miembro</v-btn
            >
        </div>
        <!-- End Breadcrump Area  -->

        <!-- Start Blog Details Area  -->
        <div class="rn-blog-details  mt-5  bg_color--1">
            <v-container>
                <div class="editor"></div>
                <editor-content :editor="editor" />
            </v-container>
        </div>

        <div class="rn-blog-details  mt-5 pb--70 bg_color--1">
            <v-container v-if="$store.state.miembros.length > 0">
                <div class="text-h4">Miembros</div>

                <v-row class=" mt-5">
                    <v-col
                        cols="12"
                        sm="6"
                        lg="3"
                        v-for="(m, index) in $store.state.miembros"
                        :key="index"
                    >
                        <MiembroCard :miembro="m" />
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Agregar Miembro
                </v-card-title>

                <v-card-text class="mt-3">
                    <v-row class="items-center">
                        <v-col cols="12" sm="6">
                            <label for="Foto">
                                <v-img :src="miembro.Foto"> </v-img>
                                <input
                                    type="file"
                                    style="display:none"
                                    id="Foto"
                                    @change="(e) => preview(e)"
                                />
                            </label>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Nombre"
                                v-model="miembro.Nombre"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                label="Email"
                                v-model="miembro.Email"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col>
                            <v-text-field
                                label="Telefono"
                                v-model="miembro.Telefono"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Titulo"
                                v-model="miembro.Titulo"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="">
                        <v-col>
                            <v-text-field
                                label="Fabeook"
                                v-model="miembro.Facebook"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Twitter"
                                v-model="miembro.Twitter"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="">
                        <v-col>
                            <v-text-field
                                label="Instagram"
                                v-model="miembro.Instagram"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        label="Frase"
                        v-model="miembro.Frase"
                        outlined
                    ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="
                            $store
                                .dispatch('create_Miembro', miembro)
                                .then(() => {
                                    dialog = false;
                                    $store.dispatch('get_Miembros', {
                                        MinisterioID:
                                            $route.params.MinisterioID,
                                    });
                                })
                        "
                    >
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <Footer />
    </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import moment from "moment";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import feather from "feather-icons";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import MiembroCard from "../../components/card/MiembroCard.vue";
export default {
    components: {
        Header,
        Footer,
        ValidationObserver,
        ValidationProvider,
        Editor,
        EditorContent,
        MiembroCard,
    },
    data() {
        return {
            dialog: false,
            editor: null,
            articulo: Object,
            logo: require("../../assets/images/logo/logo.png"),
            miembro: {
                MinisterioID: this.$route.params.MinisterioID,
                Foto: require("../../assets/images/galery.png"),
                Nombre: "",
                Email: "",
                Telefono: "",
                Titulo: "",
                Frase: "",
                Facebook: "",
                Twitter: "",
                Instagram: "",
            },
            items: [
                {
                    thumb: require("../../assets/images/blog/bl-big-01.jpg"),
                    src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
                },
            ],
            metaList: [
                {
                    icon: "clock",
                    text: "May 18, 2020",
                },
                {
                    icon: "user",
                    text: "Fatima",
                },
                // {
                //     icon: "message-circle",
                //     text: "15 Comments",
                // },
                // {
                //     icon: "heart",
                //     text: "Like",
                // },
            ],
            index: null,
            miembros: [
                {
                    Foto: require("../../assets/images/galery.png"),
                    Nombre: "Jesus Torres",
                    Email: "prjesus12@gmail.com",
                    Telefono: "787-326-9448",
                    Titulo: "Software Dev",
                    Nota:
                        "Con Él en nuestra vida podemos alcanzar todo aquello que anhelamos. Deja que guíe tu camino y sírvele con toda fe.",
                },
                {
                    Foto: require("../../assets/images/galery.png"),
                    Nombre: "Jesus Torres",
                    Email: "prjesus12@gmail.com",
                    Telefono: "787-326-9448",
                    Titulo: "Software Dev",
                    Nota:
                        "Con Él en nuestra vida podemos alcanzar todo aquello que anhelamos. Deja que guíe tu camino y sírvele con toda fe.",
                },
                {
                    Foto: require("../../assets/images/galery.png"),
                    Nombre: "Jesus Torres",
                    Email: "prjesus12@gmail.com",
                    Telefono: "787-326-9448",
                    Titulo: "Software Dev",
                    Nota:
                        "Con Él en nuestra vida podemos alcanzar todo aquello que anhelamos. Deja que guíe tu camino y sírvele con toda fe.",
                },
                {
                    Foto: require("../../assets/images/galery.png"),
                    Nombre: "Jesus Torres Torres",
                    Email: "prjesus12@gmail.com",
                    Telefono: "787-326-9448",
                    Titulo: "Software Dev",
                    Nota:
                        "Con Él en nuestra vida podemos alcanzar todo aquello que anhelamos. Deja que guíe tu camino y sírvele con toda fe.",
                },
            ],
        };
    },

    created() {
        this.getArticulo();
    },
    beforeDestroy() {
        try {
            this.editor.destroy();
        } catch (e) {}
        // this.provider.destroy();
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },

        getArticulo() {
            let _this = this;
            // this.articulo = this.$store.state.articulos.data.find(
            //     (item) => item.ArticuloID == this.$route.params.ArticuloID
            // );

            this.$store
                .dispatch("get_Ministerio", {
                    MinisterioID: this.$route.params.MinisterioID,
                })
                .then((res) => {
                    this.articulo = res;
                    this.editor = new Editor({
                        editable: false,
                        content: JSON.parse(_this.articulo.Lema),
                        extensions: [
                            StarterKit.configure({
                                history: true,
                            }),
                            Highlight,
                            TaskList,
                            TaskItem,
                            Text,
                            TextAlign.configure({
                                types: ["heading", "paragraph"],
                            }),
                        ],
                    });
                    this.metaList[0].text = moment(
                        this.articulo.updated_at
                    ).format("MM/DD/YYYY");
                    this.metaList[1].text = this.articulo.Nombre_Ministerio;
                    console.log(this.articulo);

                    this.$store.dispatch("get_Miembros", {
                        MinisterioID: this.$route.params.MinisterioID,
                    });
                });
        },

        preview(e) {
            console.log(e);
            let fileReader = new FileReader();
            let _this = this;
            fileReader.addEventListener("load", () => {
                _this.miembro.Foto = fileReader.result;
            });

            fileReader.readAsDataURL(e.target.files[0]);
        },
        
    },
};
</script>
