<template>
  <span>
    <v-btn
      v-if="$store.state.admin.Admin == 1"
      color="success"
      @click="dialog = true"
      >Agregar</v-btn
    >
    <v-row>
      <v-col
        lg="6"
        md="6"
        sm="6"
        cols="12"
        class="mt--30"
        v-for="(item, i) in $store.state.predicas.data"
        :key="i"
      >
        <div class="im_portfolio">
          <v-btn
            v-if="$store.state.admin.Admin == true"
            style="position: absolute; right: 10px; top: 10px"
            text
            icon
            color="red lighten-2"
            @click="OpenDialogEdit(item)"
          >
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.state.admin.Admin == true"
            style="position: absolute; right: 45px; top: 10px"
            text
            icon
            color="red lighten-2"
            @click="OpenDialogDelete(item)"
          >
            <v-icon>mdi-eraser</v-icon>
          </v-btn>
          <div class="thumbnail_inner">
            <div class="thumbnail">
              <img class="w-100" :src="item.Foto" alt="Portfolio Images" />
            </div>
          </div>
          <div class="content">
            <div class="inner">
              <div class="portfolio_heading">
                <h4 class="heading-title">
                  <router-link
                    :to="`/${$route.params.Nombre_Id}/predica/${item.EventoID}`"
                    >{{ item.Nombre }}</router-link
                  >
                </h4>
              </div>
              <div class="portfolio_hover">
                <router-link
                  class="rn-btn btn-opacity"
                  :to="`/${$route.params.Nombre_Id}/predica/${item.EventoID}`"
                  >Ver Mas</router-link
                >
              </div>
            </div>
          </div>
          <!-- <router-link
                        class="transparent_link"
                        :to="`predica/${item.EventoID}`"
                    ></router-link> -->
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Agregar Predica
        </v-card-title>

        <v-card-text class="mt-5">
          <v-text-field
            outlined
            label="Titulo"
            v-model="formData.Nombre"
          ></v-text-field>
          <v-file-input @change="(e) => preview(e)" />
          <img style="width: 160px" :src="formData.Foto" alt="" />
          <v-text-field
            outlined
            label="Video Url (Youtube, Facebook)"
            v-model="formData.Video"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog = false"> Cancelar </v-btn>
          <v-btn
            color="primary"
            text
            @click="
              $store.dispatch('create_Predica', formData).then((res) => {
                dialog = false;
                $store.dispatch('get_Predicas');
              })
            "
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="edit" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Editar <b class="ml-2">{{ borrando.Nombre }}</b>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-text-field
            name="name"
            label="Nombre"
            id="id"
            v-model="borrando.Nombre"
            outlined
          ></v-text-field>
          <v-text-field
            outlined
            label="Fecha Inicial"
            type="datetime-local"
            v-model="borrando.Fecha"
          >
            <template v-slot:prepend-inner>
              <v-checkbox
                v-model="borrando.useFecha"
                style="margin: 0; padding: 0"
              />
            </template>
          </v-text-field>
          <v-text-field
            outlined
            label="Fecha Finalizado"
            type="datetime-local"
            v-model="borrando.Fecha_Finalizado"
          >
            <template v-slot:prepend-inner>
              <v-checkbox
                v-model="borrando.useFecha_Finalizado"
                style="margin: 0; padding: 0"
              />
            </template>
          </v-text-field>
          <v-checkbox
            label="Agendar"
            class="my-n5"
            v-model="borrando.Agendar"
            true-value="1"
            false-value="0"
          />
          <v-file-input @change="(e) => preview(e)" />
          <img style="width: 160px" :src="borrando.Foto" alt="" />
          <v-text-field
            name="name"
            label="Video"
            id="id"
            v-model="borrando.Video"
            outlined
            clearable
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="edit = false"> Cancelar </v-btn>
          <v-btn
            color="primary"
            text
            @click="
              $store.dispatch('update_Predica', borrando).then(() => {
                edit = false;
                $store.dispatch('get_Predicas');
                $store.dispatch('get_Last_Predica');
              })
            "
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="borrar" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Borrar <b class="ml-2">{{ borrando.Nombre }}</b>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="borrar = false"> Cancelar </v-btn>
          <v-btn
            color="primary"
            text
            @click="
              $store.dispatch('delete_Predica', borrando).then(() => {
                borrar = false;
                $store.dispatch('get_Predicas');
              })
            "
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      formData: {
        Nombre: "",
        Video: "",
        useFecha: true,
        Fecha: moment().format("yyyy-MM-DDThh:mm"),
        useFecha_Finalizado: true,
        Fecha_Finalizado: moment().format("yyyy-MM-DDThh:mm"),
      },
      borrando: {},
      dialog: false,
      edit: false,
      borrar: false,
      portfolioContent: [
        {
          src: require("../../assets/images/portfolio/interior/portfolio-15.jpg"),
          category: "Development",
          title: "Web Design",
          description:
            "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.",
        },
        {
          src: require("../../assets/images/portfolio/interior/portfolio-12.jpg"),
          category: "Product Design",
          title: "App Development",
          description:
            "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
        },
        {
          src: require("../../assets/images/portfolio/interior/portfolio-13.jpg"),
          category: "Application",
          title: "Photoshop Design",
          description:
            "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
        },
        {
          src: require("../../assets/images/portfolio/interior/portfolio-14.jpg"),
          category: "Application",
          title: "Photoshop Design",
          description:
            "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("get_Predicas");
  },
  watch: {
    "$store.state.iglesia"() {
      this.$store.dispatch("get_Predicas");
    },
  },
  methods: {
    preview(e) {
      var reader = new FileReader();
      reader.addEventListener("load", (readFile) => {
        console.log(reader.result);
        this.formData.Foto = reader.result;
      });
      reader.readAsDataURL(e);
    },
    OpenDialog(data) {
      this.dialog = true;
      // this.formData = { ...data };
    },
    OpenDialogDelete(data) {
      this.borrar = true;
      this.borrando = {
        ...data,
        Fecha: moment(data.Fecha).format("yyyy-MM-DD"),
      };
    },
    OpenDialogEdit(data) {
      this.edit = true;
      this.borrando = {
        ...data,
        useFecha: true,
        Fecha: moment(data.Fecha).format("yyyy-MM-DDThh:mm"),
        useFecha_Finalizado: true,
        Fecha_Finalizado: moment(data.Fecha_Finalizado).format(
          "yyyy-MM-DDThh:mm"
        ),
      };
    },
  },
};
</script>
