var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mode == 'create' && _vm.$store.state.admin.Admin == 1)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.OpenDialog()}}},[_vm._v("Agregar")]):_vm._e(),(_vm.mode == 'edit' && _vm.$store.state.admin.Admin == 1)?_c('v-row',{staticClass:"w-100 poss_absolute justify-end z-index-1"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":_vm.EditData}},[_vm._v("Editar")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.borrarData()}}},[_vm._v("Borrar")])],1):_vm._e(),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Crear Ministerio ")]),_c('v-card-text',{staticClass:"mt-5"},[_c('v-file-input',{attrs:{"label":"Foto","outlined":""},on:{"change":function (e) { return _vm.preview(e); }}}),_c('div',{staticClass:"d-flex justify-center mb-5"},[_c('v-img',{attrs:{"max-width":"120","src":_vm.formData.Foto}})],1),_c('v-text-field',{attrs:{"name":"name","label":"Titulo","id":"id","outlined":""},model:{value:(_vm.formData.Titulo),callback:function ($$v) {_vm.$set(_vm.formData, "Titulo", $$v)},expression:"formData.Titulo"}}),_c('shareEditor')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store
                            .dispatch(
                                _vm.mode == 'create'
                                    ? 'create_Ministerio'
                                    : 'update_Ministerio',
                                _vm.formData
                            )
                            .then(function () {
                                _vm.dialog = false;
                                _vm.$store.dispatch('get_Ministerios');
                            })}}},[_vm._v(" Continuar ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Borrar ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store
                            .dispatch('delete_Ministerio', _vm.formData)
                            .then(function () {
                                _vm.dialog2 = false;
                                _vm.$store.dispatch('get_Ministerios');
                            })}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }