<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" :src="logo" />
        </Header>
        <!-- End Header Area -->

        <!-- Start Breadcrump Area  -->
        <div
            class="rn-page-title-area pt--120 pb--190 bg-fit-cover"
            data-black-overlay="7"
            :style="`background-image: url(${articulo.Foto})`"
        >
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-center blog-single-page-title pt--100">
                            <h2 class="heading-title theme-gradient">
                                {{ articulo.Nombre }}
                            </h2>
                            <ul
                                class="justify-center blog-meta d-flex align-center"
                            >
                                <li
                                    class="d-flex"
                                    v-for="(meta, i) in metaList"
                                    :key="i"
                                >
                                    <span v-html="iconSvg(meta.icon)"></span
                                    >{{ meta.text }}
                                </li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- End Breadcrump Area  -->

        <!-- Start Blog Details Area  -->
        <div class="pt--110 pb--70">
            <v-container class="d-flex justify-center">
                <!-- <iframe
                    :srcdoc="articulo.Video"
                    height="400px"
                    width="600px"
                    controls
                ></iframe> -->
                <iframe
                    v-if="video != ''"
                    :src="video"
                    width="100%"
                    height="800"
                    style="border:none,overflow:hidden"
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    target="_blank"
                ></iframe>
                <span id="video"></span>
            </v-container>
        </div>

        <Footer />
    </div>
</template>

<script>
import moment from "moment";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import feather from "feather-icons";

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            video: "",
            articulo: Object,
            logo: require("../../assets/images/logo/logo.png"),
            formData: {
                name: "",
                email: "",
                subject: "",
                message: "",
            },
            items: [
                {
                    thumb: require("../../assets/images/blog/bl-big-01.jpg"),
                    src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
                },
            ],
            metaList: [
                {
                    icon: "clock",
                    text: "May 18, 2020",
                },
                {
                    icon: "user",
                    text: "Fatima",
                },
                // {
                //     icon: "message-circle",
                //     text: "15 Comments",
                // },
                // {
                //     icon: "heart",
                //     text: "Like",
                // },
            ],
            index: null,
        };
    },

    created() {
        this.getArticulo();
    },
    beforeDestroy() {
        try {
            this.editor.destroy();
        } catch (e) {}
        // this.provider.destroy();
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
        onSubmit() {
            console.log(this.formData);
        },
        getArticulo() {
            let _this = this;
            this.$store
                .dispatch("get_Predica", {
                    EventoID: this.$route.params.EventoID,
                })
                .then((res) => {
                    this.articulo = res;
                    console.log(this.articulo);

                    this.metaList[0].text = moment(
                        this.articulo.Fecha
                    ).format("MM/DD/YYYY");
                    this.metaList[1].text = this.articulo.Nombre;

                    this.getVideoSrc(this.articulo.Video);
                });
        },
        getVideoSrc(item) {
            let s = item.split(/(src=")/);
            console.log(s[2].split('"'));
            let ss = s[2].split('"');
            this.video = ss[0];

            // var loc = ss[0];
            // window.parent.location.replace(loc);
        },
    },
};
</script>
