<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up"
      v-model="drawer"
      absolute
      temporary
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img :src="$store.state.iglesia.Logo" alt="brand-image" />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>

      <v-list>
        <v-list-item
          link
          v-for="child in $store.state.menu"
          :to="child.to"
          :key="child.title"
          :ripple="false"
          exact
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <span v-if="$store.state.admin.Admin == 1">
          <v-list-item
            link
            v-for="child in $store.state.menuAdmin"
            :to="child.to"
            :key="child.title"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      elevation="0"
      color="transparent"
      absolute
      class="default-header"
    >
      <a @click="dialog = true" class="logo">
        <img :src="$store.state.iglesia.Logo" alt="brand-image" />
        <div class="">{{ $store.state.iglesia.Nombre }}</div>
      </a>
      <!-- End brand logo -->
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
        <span v-for="(item, index) in $store.state.menu" :key="index">

          <button @click="$router.push(item.to)" class="donaciones px-4 py-2 rounded-pill" v-if="item.btn">{{ item.title }}</button>

          <v-btn v-else :ripple="false" text :to="item.to" exact>{{
            item.title
          }}</v-btn>
        </span>
        <v-menu
          v-if="$store.state.admin.Admin == 1"
          open-on-hover
          :position-y="80"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              link
              :ripple="false"
              text
              class="scrollactive-item"
            >
              Editar
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in $store.state.menuAdmin"
              :key="index"
              :to="item.to"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <!-- End header menu item -->

      <v-btn
        :ripple="false"
        icon
        class="ma-0 pa-0 hidden-md-and-up menu_icon"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>

      <a
        @click="
          Goto(
            `/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_4}/${$store.state.lastPredica.EventoID}`
          )
        "
        :class="
          $store.state.counterEnded == true
            ? 'btn-default btn-border btn-opacity poss_relative text-center mobile-self-center'
            : 'white--text'
        "
        v-if="$store.state.lastPredica"
      >
        <flip-countdown
          v-if="$store.state.lastPredica && $store.state.counterEnded == false"
          :deadline="$store.state.lastPredica.Fecha"
          @timeElapsed="timeElapsedHandler"
          :labels="labels"
        ></flip-countdown>
        <span v-else style="line-height: 0.8em"
          >Ver ahora

          <small
            class="text-center"
            style="font-size: 10px; word-space: normal"
            >{{ $store.state.lastPredica.Nombre }}</small
          >
        </span>
      </a>

      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Acceder a su cuenta
        </v-card-title>

        <v-card-text class="mt-8">
          <v-text-field
            label="Email"
            outlined
            v-model="$store.state.admin.Email"
          />
          <v-text-field
            label="Password"
            outlined
            v-model="$store.state.admin.Password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1"
            :type="show1 ? 'text' : 'password'"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn v-if="$store.state.admin.Admin" color="primary" text @click="$store.dispatch('Logout')"> Logout </v-btn>
          
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="Login"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 red white--text">
          Problema de Autentificación
        </v-card-title>

        <v-card-text class="mt-5"> Email/Contraseña equivocada </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorDialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
import feather from "feather-icons";
export default {
  components: { FlipCountdown },
  data() {
    return {
      show1: false,
      drawer: false,
      dialog: false,
      errorDialog: false,
      icon: "menu",
      closeIcon: "x",
      counterEnded: false,
      labels: {
        days: "DD",
        hours: "HH",
        minutes: "MM",
        seconds: "SS",
      },
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    Login() {
      this.$store.dispatch("Login").then((res) => {
        this.dialog = false;
        if (res == "Error de Login") {
          this.errorDialog = true;
        }
      });
    },
    timeElapsedHandler(e) {
      console.log(e);
      // this.counterEnded = true;
      this.$store.state.counterEnded = true;
    },
    Goto(web) {
      if (this.$store.state.counterEnded == true) {
        this.$router.push(web);
      }
    },
  },
};
</script>
<style lang="scss">
.mobile-self-center {
  width: 240px !important;
  line-height: 0.02px;
  @media (max-width: 767.98px) {
    width: 75% !important;
    position: absolute;
    top: 0px;
    left: 10%;
    small::before {
      content: " ";
      clear: right;
      display: block;
    }
  }
  small::before {
    content: " ";
    clear: right;
    display: block;
  }
}
.self-align-start {
  align-self: start;
}
.flip-clock[data-v-78efe7f6] {
  text-align: center;
  perspective: 600px;
  margin: 0 auto;
}
.flip-clock *[data-v-78efe7f6],
.flip-clock *[data-v-78efe7f6]:before,
.flip-clock *[data-v-78efe7f6]:after {
  box-sizing: border-box;
}
.flip-clock__piece[data-v-78efe7f6] {
  display: inline-block;
  margin: 0 0.2vw;
}
@media (min-width: 1000px) {
  .flip-clock__piece[data-v-78efe7f6] {
    margin: 0 -5px !important;
  }
}
.flip-clock__slot[data-v-78efe7f6] {
  font-size: 16px !important;
  line-height: 1.5;
  display: block;
}
.flip-card[data-v-78efe7f6] {
  display: flex !important;
  position: relative !important;
  padding-bottom: 0.72em;
  font-size: 16px !important;
  line-height: 0.95;
  width: 60px !important;
  /* background-color: #fff; */
  justify-content: center;
}
@media (min-width: 1000px) {
  .flip-clock__slot[data-v-78efe7f6] {
    font-size: 1.2rem;
  }
  .flip-card[data-v-78efe7f6] {
    font-size: 3rem;
  }
}
.flip-card__top[data-v-78efe7f6],
.flip-card__bottom[data-v-78efe7f6],
.flip-card__back-bottom[data-v-78efe7f6],
.flip-card__back[data-v-78efe7f6]::before,
.flip-card__back[data-v-78efe7f6]::after {
  display: flex !important;
  justify-content: center;
  color: #cca900;
  background: #222;
  padding: 0.23em 0.15em 0.4em;
  border-radius: 0.15em 0.15em 0 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 2.1em !important;
  height: 0.72em;
}
.flip-card__top-4digits[data-v-78efe7f6],
.flip-card__bottom-4digits[data-v-78efe7f6],
.flip-card__back-bottom-4digits[data-v-78efe7f6],
.flip-card__back-4digits[data-v-78efe7f6]::before,
.flip-card__back-4digits[data-v-78efe7f6]::after {
  display: flex !important;
  color: #cca900;
  background: #222;
  padding: 0.23em 0.15em 0.4em;
  border-radius: 0.15em 0.15em 0 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 2.65em;
  height: 0.72em;
  justify-content: center !important;
  position: absolute !important;
}
.flip-card__bottom[data-v-78efe7f6],
.flip-card__back-bottom[data-v-78efe7f6],
.flip-card__bottom-4digits[data-v-78efe7f6],
.flip-card__back-bottom-4digits[data-v-78efe7f6] {
  color: #ffdc00;
  position: absolute !important;
  top: 50%;
  left: 22% !important;
  border-top: solid 1px #000;
  background: #393939;
  border-radius: 0 0 0.15em 0.15em;
  pointer-events: none;
  overflow: hidden;
  z-index: 2;
  display: flex !important;
  justify-content: center !important;
}
.flip-card__back-bottom[data-v-78efe7f6],
.flip-card__back-bottom-4digits[data-v-78efe7f6] {
  z-index: 1;
}
.flip-card__bottom[data-v-78efe7f6]::after,
.flip-card__back-bottom[data-v-78efe7f6]::after,
.flip-card__bottom-4digits[data-v-78efe7f6]::after,
.flip-card__back-bottom-4digits[data-v-78efe7f6]::after {
  display: block;
  margin-top: -0.72em;
}
.flip-card__back[data-v-78efe7f6]::before,
.flip-card__bottom[data-v-78efe7f6]::after,
.flip-card__back-bottom[data-v-78efe7f6]::after,
.flip-card__back-4digits[data-v-78efe7f6]::before,
.flip-card__bottom-4digits[data-v-78efe7f6]::after,
.flip-card__back-bottom-4digits[data-v-78efe7f6]::after {
  content: attr(data-value);
  /* background-color: #fff !important;
    display: flex !important;
    justify-content: center !important;
    position: absolute !important;
    left: 22% !important; */
}
.flip-card__back[data-v-78efe7f6],
.flip-card__back-4digits[data-v-78efe7f6] {
  position: absolute;
  top: 0;
  height: 100%;
  left: 22% !important;
  pointer-events: none;
}
.flip-card__back[data-v-78efe7f6]::before,
.flip-card__back-4digits[data-v-78efe7f6]::before {
  position: relative;
  overflow: hidden;
  z-index: -1;
}
.flip .flip-card__back[data-v-78efe7f6]::before,
.flip .flip-card__back-4digits[data-v-78efe7f6]::before {
  z-index: 1;
  animation: flipTop-data-v-78efe7f6 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}
.flip .flip-card__bottom[data-v-78efe7f6],
.flip .flip-card__bottom-4digits[data-v-78efe7f6] {
  transform-origin: center top;
  animation-fill-mode: both;
  animation: flipBottom-data-v-78efe7f6 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
}
@keyframes flipTop-data-v-78efe7f6 {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }
  0%,
  99% {
    opacity: 1;
  }
  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}
@keyframes flipBottom-data-v-78efe7f6 {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
    z-index: 5;
  }
}
.donaciones {
  background-color: var(--color-primary);
  color: white;
  font-size: 20px;

  &:hover {
    background-color: white;
    color: var(--color-primary);
  }
}
</style>
