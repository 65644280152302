<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" :src="logo" />
        </Header>
        <!-- End Header Area -->

        <!-- Start Slider Area  -->
        <div class="slider-wrapper poss_relative">
            <v-btn
                v-if="$store.state.iglesia.Video"
                large
                icon
                class="stop-btn"
                @click="mute = !mute"
            >
                <v-icon color="white" v-if="mute == false"
                    >mdi-volume-off</v-icon
                >
                <v-icon color="white" v-else>mdi-volume-high</v-icon>
            </v-btn>

            <div
                class="justify-center slide slide-style-2 slider-video-bg d-flex align-center"
                data-black-overlay="6"
            >
                <v-container>
                    <v-row align="center">
                        <v-col cols="12">
                            <div class="text-center inner pt_sm--40">
                                <span class="theme-color font-700">{{
                                    $store.state.iglesia.Nombre
                                }}</span>
                                <h1 class="heading-title mt--20">
                                    {{ $store.state.iglesia.Lema }}
                                </h1>
                                <p class="description">
                                    {{ $store.state.iglesia.SubLema }}
                                </p>
                                <router-link
                                    class="btn-default btn-large mt--30"
                                    :to="
                                        `/${$route.params.Nombre_Id}/contact`
                                    "
                                    ><span>Contactanos</span></router-link
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-container>

                <div class="video-background ">
                 
                    <span v-if="$store.state.iglesia.Video">
                        <video
                            :autoplay="true"
                            playsinline=""
                            loop=""
                            :muted="mute"
                            id="video-background"
                            
                            class="object-fit-video"
                            :src="$store.state.iglesia.Video"
                        ></video>
                    </span>
                    <img
                        v-else
                        :src="$store.state.iglesia.SLIDE_1_ICON"
                        alt=""
                    />
                </div>
            </div>
            <!-- End Slider Area  -->

            <!-- Start video-image-wrapper -->
            <!-- <div class="video-image-wrapper agency-thmub-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="thumb position-relative">
                                <CoolLightBox
                                    :items="items"
                                    :index="index"
                                    @close="index = null"
                                >
                                </CoolLightBox>

                                <div
                                    v-for="(image, imageIndex) in items"
                                    :key="imageIndex"
                                >
                                    <img
                                        class="w-100"
                                        :src="image.thumb"
                                        alt="About Images"
                                    />
                                    <a
                                        @click="index = imageIndex"
                                        class="video-popup position-top-center theme-color"
                                        ><span class="play-icon"></span
                                    ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- End video-image-wrapper -->
        </div>

        <!-- Start Portfolio Area -->
        <div class="portfolio-area ptb--120 bg_color--1">
            <div class="portfolio-wrapper">
                <v-container>
                    <v-row>
                        <v-col lg="12">
                            <div class="text-center section-title">
                                <h2 class="heading-title">
                                    {{ $store.state.iglesia.Lema }}
                                </h2>
                                <p class="description">
                                    {{ $store.state.iglesia.SubLema }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="mt-10">
                        <PortfolioThree />
                    </div>
                </v-container>
            </div>
        </div>
        <!-- End Portfolio Area -->

        <!-- Start Contact -->
        <div class="rn-contact-area pt-2 pb-5 " id="contact">
            <div class="contact-form--2">
                <v-container>
                    <Contact>
                        <!-- <img
                            slot="contact-img"
                            class="w-100"
                            src="../../assets/images/about/about-13.jpg"
                            alt="contact images"
                        /> -->
                        <img
                            slot="contact-img"
                            class="w-100"
                            :src="$store.state.iglesia.Peticion_Foto"
                            alt="contact images"
                        />
                    </Contact>
                </v-container>
            </div>
        </div>

        <!-- End Contact -->

        <!-- Start About Area  -->
        <!-- <div class="about-area ptb--120 bg_color--1">
            <div class="about-wrapper">
                <div class="container">
                    <AboutThree />
                </div>
            </div>
        </div> -->
        <!-- Start About Area  -->

        <!-- Start Counterup Area -->
        <!-- <div class="rn-counterup-area ptb--120 bg_color--5">
            <v-container>
                <v-row class="row">
                    <v-col cols="12">
                        <div class="text-center section-title">
                            <span class="subtitle">Experts growts</span>
                            <h2 class="heading-title">Our Company Growth</h2>
                            <p class="description">
                                We have grown strength over the past 20 years.
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <CounterOne />
            </v-container>
        </div> -->
        <!-- End Counterup Area -->

        <!-- Start Testimonial Area  -->
        <!-- <div class="rn-testimonial-area bg_color--1 ptb--120">
            <v-container>
                <Testimonial />
            </v-container>
        </div> -->
        <!-- Start Testimonial Area  -->

        <!-- Start Blog Area  -->
        <!-- <div class="rn-blog-area ptb--120 bg_color--5">
            <v-container>
                <v-row align="end" class="mb--20">
                    <v-col lg="6" md="6" sm="12" cols="12">
                        <div class="text-left section-title">
                            <span class="subtitle">what's stories on</span>
                            <h2 class="heading-title">Latest News</h2>
                            <p class="description">
                                There are many variations of passages of Lorem
                                Ipsum available,
                                <br />
                                but the majority have suffered alteration.
                            </p>
                        </div>
                    </v-col>
                    <v-col lg="6" md="6" sm="12" cols="12">
                        <div
                            class="text-left blog-btn text-md-right mt_sm--10 mt_md--10"
                        >
                            <a
                                class="btn-transparent rn-btn-dark"
                                href="blog.html"
                                ><span class="text">View All News</span></a
                            >
                        </div>
                    </v-col>
                </v-row>
                <Blog />
            </v-container>
        </div> -->
        <!-- End Blog Area  -->

        <!-- Start Brand Area  -->
        <!-- Start Brand Area -->
        <!-- <div class="rn-brand-area bg_color--1 ptb--120">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-center section-title mb--30">
                            <span class="subtitle">Top clients</span>
                            <h2 class="heading-title">
                                We worked with brands.
                            </h2>
                        </div>
                    </v-col>
                </v-row>
                <Brand />
            </v-container>
        </div> -->
        <!-- End Brand Area -->

        <!-- Start Footer Area  -->
        <FooterTwo />
        <!-- End Footer Area  -->
    </div>
</template>

<script>
import Header from "../../components/header/Header";
import Portfolio from "../../components/portfolio/Portfolio";
import ServiceTwo from "../../components/service/ServiceTwo";
import AboutThree from "../../components/about/AboutThree";
import CounterOne from "../../components/counter/CounterOne";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/Blog";
import Brand from "../../components/brand/Brand";
import FooterTwo from "../../components/footer/FooterTwo";
import PortfolioEight from "../../components/portfolio/PortfolioEight.vue";
import PortfolioFive from "../../components/portfolio/PortfolioFive.vue";
import PortfolioFour from "../../components/portfolio/PortfolioFour.vue";
import PortfolioNine from "../../components/portfolio/PortfolioNine.vue";
import PortfolioTwo from "../../components/portfolio/PortfolioTwo.vue";
import PortfolioTen from "../../components/portfolio/PortfolioTen.vue";
import PortfolioThree from "../../components/portfolio/PortfolioThree.vue";
import Contact from "../../components/contact/Contact.vue";

export default {
    components: {
        Header,
        Portfolio,
        ServiceTwo,
        AboutThree,
        CounterOne,
        Testimonial,
        Blog,
        Brand,
        FooterTwo,
        PortfolioEight,
        PortfolioFive,
        PortfolioFour,

        PortfolioNine,
        PortfolioTwo,
        PortfolioTen,
        PortfolioThree,
        Contact,
    },
    data() {
        return {
            mute: false,
            items: [
                {
                    thumb: require("../../assets/images/blog/bl-big-02.jpg"),
                    src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
                },
            ],
            index: null,
            logo: require("../../assets/images/logo/logo.png"),
        };
    },
    created() {
        this.items[0].src = this.$store.state.iglesia.Video;
    },
    methods: {
        // mute() {
        //     var myVideo = document.getElementById("video-background");
        //     myVideo.muted = true;
        // },
    },
};
</script>

<style lang="scss">
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
    .slider-video-bg.slide.slide-style-2 {
        padding-top: 160px;
        padding-bottom: 260px;
        min-height: auto;
    }
}
.stop-btn {
    position: absolute;
    bottom: 0;

    z-index: 10;
}

.object-fit-video{
    object-fit: cover;
    width: 100%;
    @media (max-width: 767.98px){
    	width: auto;
    }
}
</style>
