<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" :src="logo" />
        </Header>
        <!-- End Header Area -->

        <!-- Start Breadcrump Area  -->
        <div
            class="breadcrumb-area rn-bg-color ptb--120"
            
        >
            <v-container>
                <v-row>
                    <v-col lg="12">
                        <div class="breadcrumb-inner pt--100">
                            <h2 class="heading-title">{{$store.state.iglesia.Nav_5}}</h2>
                            <ul class="page-list">
                                <li><router-link :to="`/${$route.params.Nombre_Id}`">Inicio</router-link></li>
                                <li class="current-page">{{$store.state.iglesia.Nav_5}}</li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <!-- <blogeditor /> -->
        <div class="ma-5">

        <editor-ministerios />
        </div>
        <!-- End Breadcrump Area  -->

        <!-- Start blog Area  -->
        <div class="rn-blog-area pt-10  bg_color--1">
            <v-container>
                <ministerios-list />
                <v-row>
                    <v-col lg="12">
                        <div class="text-center rn-pagination mt--60 mt_sm--30">
                            <Pagination
                                store="get_Ministerios"
                                :total="
                                    Math.ceil(
                                        $store.state.ministerios.total /
                                            $store.state.ministerios.per_page
                                    )
                                "
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- Start blog Area  -->

        <Footer />
    </div>
</template>

<script>
import Header from "../../components/header/Header";
import BlogThree from "../../components/blog/BlogThree";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
// import Quill from "quill";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import blogeditor from "../../components/blogeditor/index.vue";
import MinisteriosList from '../../components/blog/MinisteriosList.vue';
import EditorMinisterios from '../../components/editores/EditorMinisterios.vue';
export default {
    components: {
        Header,
        BlogThree,
        Pagination,
        Footer,
        EditorContent,
        blogeditor,
        MinisteriosList,
        EditorMinisterios,
    },
    data() {
        return {
            logo: require("../../assets/images/logo/logo.png"),
            editor: null,
        };
    },
    watch: {
        "$store.state.iglesia"() {
            this.$store.dispatch("get_Ministerios");
        },
    },
    created() {
        this.$store.dispatch("get_Ministerios");
        // this.editor = new Editor({
        //     content: "<p>I’m running tiptap with Vue.js. 🎉</p>",
        //     extensions: [StarterKit],
        // });
    },

    methods: {},
};
</script>
