<template>
    <v-row align="start" class="row--35">
        <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
            <div class="text-left section-title mb--50">
                <span class="subtitle">{{$store.state.iglesia.Peticion_Titulo}}</span>
                <h2 class="heading-title">{{$store.state.iglesia.Peticion_SubTitulo}}</h2>
                <div
                    class="im_address"
                    v-if="$store.state.iglesia.Telefono != ''"
                >
                    <span>Llamanos directamente</span
                    ><a
                        class="link im-hover"
                        :href="`tel:${$store.state.iglesia.Telefono}`"
                        >{{ $store.state.iglesia.Telefono }}</a
                    >
                </div>
                <div
                    class="im_address mt--5"
                    v-if="$store.state.iglesia.Email_Contacto != ''"
                >
                    <span>Email de contacto:</span
                    ><a
                        class="link im-hover"
                        :href="`mailto:${$store.state.iglesia.Email_Contacto}`"
                        >{{ $store.state.iglesia.Email_Contacto }}</a
                    >
                </div>
            </div>

            <v-text-field
                label="Nombre"
                v-model="formData.Nombre"
                outlined
                :background-color="$store.state.iglesia.Layout == '7' ? 'white' : ''"
            ></v-text-field>
            <v-text-field
                label="Email"
                v-model="formData.Email"
                outlined
                :background-color="$store.state.iglesia.Layout == '7' ? 'white' : ''"
            ></v-text-field>
            <v-text-field
                label="Telefono"
                v-model="formData.Telefono"
                outlined
                :background-color="$store.state.iglesia.Layout == '7' ? 'white' : ''"
            ></v-text-field>
            <v-textarea
                label="Mensaje"
                outlined
                v-model="formData.Mensaje"
                :background-color="$store.state.iglesia.Layout == '7' ? 'white' : ''"
            ></v-textarea>
            <button class="btn-default" @click="onSubmit">
                Confirmar
            </button>
            <!-- <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.name"
                  placeholder="Your Name *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  rules="required|email"
                  v-model="formData.email"
                  placeholder="Your email *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="subject"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.subject"
                  placeholder="Write a Subject"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <textarea
                  v-model="formData.message"
                  placeholder="Your Message"
                ></textarea>
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <button class="btn-default" type="submit" value="submit">
              Confirmar
            </button>
          </form>
        </ValidationObserver> -->
        </v-col>
        <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
            <div class="thumbnail mb_md--40 mb_sm--40">
                <!-- <slot name="contact-img"></slot> -->
                <img :src="$store.state.iglesia.Peticion_Foto" />
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data() {
        return {
            formData: {
                Nombre: "",
                Telefono: "",
                Email: "",
                Mensaje: "",
            },
        };
    },
    methods: {
        onSubmit() {
            console.log(this.formData);
            this.$store.dispatch("create_peticion", this.formData).then(() => {
                this.formData = {
                    Nombre: "",
                    Telefono: "",
                    Email: "",
                    Mensaje: "",
                };
            });
        },
    },
};
</script>

<style lang="scss">
// .form-wrapper label input,
// .form-wrapper label textarea {
//   margin-bottom: 0;
// }
// .form-wrapper label {
//   margin-bottom: 20px;
// }
</style>
