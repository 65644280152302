<template>
  <v-row class="mt_dec--30">
  
    <!-- Start Blog Area  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in $store.state.articulos.data"
      :key="i"
    >
      <div class="im_box mt--30 relative">
       
        <EditarArticulo :ArticuloID="item.ArticuloID" />
        <!-- <blogedit :articulo="item" /> -->
        <!-- <v-btn
        v-if="$store.state.admin.Admin == true"
        style="position:absolute;right:10px;top:10px;"
        text
        icon
        color="red lighten-2"
      >
        <v-icon>mdi-file-document-edit-outline</v-icon>
      </v-btn> -->
        <div class="thumbnail">
          
            <img class="w-100" :src="item.Foto" alt="Blog Images" />
         
        </div>
        <div class="content">
          <div class="inner">
            <div class="content_heading">
              <div class="category_list">
                <router-link :to="`/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_6}/${item.ArticuloID}`">{{
                  item.Autor
                }}</router-link>
              </div>
              <h4 class="heading-title">
                <router-link :to="`/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_6}/${item.ArticuloID}`">{{ item.Titulo }}</router-link>
              </h4>
            </div>
            <div class="content_footer">
              <router-link :to="`/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_6}/${item.ArticuloID}`" class="rn-btn btn-opacity">
                Ver mas
              </router-link>
            </div>
          </div>
          <!-- <router-link
            class="transparent_link"
            to="/${$store.state.iglesia.nav_6}"
          ></router-link> -->
        </div>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
import blogedit from "../blogeditoredit/index.vue"
import EditarArticulo from './EditarArticulo.vue';
  export default {
    components:{
      blogedit,
        EditarArticulo
    },
    data() {
      return {
        blogContent: [
          {
            src: require("../../assets/images/blog/blog-01.jpg"),
            title: "What is the Difference between Web and Brand.",
            category: "App Development",
          },
          {
            src: require("../../assets/images/blog/blog-02.jpg"),
            title: "A big ticket gone to be an interesting look New York.",
            category: "React App",
          },
          {
            src: require("../../assets/images/blog/blog-03.jpg"),
            title: "Getting tickets to the big show have a closer look.",
            category: "Photoshop",
          },
          {
            src: require("../../assets/images/blog/blog-04.jpg"),
            title: "What is the Difference between Web and Brand.",
            category: "App Development",
          },
          {
            src: require("../../assets/images/blog/blog-05.jpg"),
            title: "A big ticket gone to be an interesting look New York.",
            category: "React App",
          },
          {
            src: require("../../assets/images/blog/blog-06.jpg"),
            title: "Getting tickets to the big show have a closer look.",
            category: "Photoshop",
          },
        ],
      };
    },
  };
</script>
