<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" :src="logo" />
        </Header>
        <!-- End Header Area -->

        <!-- Start Breadcrump Area  -->
        <div class="breadcrumb-area rn-bg-color ptb--120">
            <v-container>
                <v-row>
                    <v-col lg="12">
                        <div class="breadcrumb-inner pt--100">
                            <h2 class="heading-title">
                                {{ $store.state.iglesia.Nav_4 }}
                            </h2>
                            <ul class="page-list">
                                <li>
                                    <router-link
                                        :to="
                                            `/${$route.params.Nombre_Id}`
                                        "
                                        >Inicio</router-link
                                    >
                                </li>
                                <li class="current-page">
                                    {{ $store.state.iglesia.Nav_4 }}
                                </li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-btn
            color="success"
            class="ma-5"
            @click="OpenDialog()"
            v-if="$store.state.admin.Admin == 1"
            >Agregar</v-btn
        >
        <!-- End Breadcrump Area  -->

        <!-- Start blog Area  -->
        <div class="rn-blog-area pt-10  bg_color--1">
            <v-container>
                <Predicas />
                <v-row>
                    <v-col lg="12">
                        <div class="text-center rn-pagination mt--60 mt_sm--30">
                            <Pagination
                                store="get_Predicas"
                                :total="
                                    Math.ceil(
                                        $store.state.predicas.total /
                                            $store.state.predicas.per_page
                                    )
                                "
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- Start blog Area  -->

        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Agregar Predica
                </v-card-title>

                <v-card-text class="mt-5">
                    <v-text-field
                        outlined
                        label="Titulo"
                        v-model="formData.Nombre"
                    ></v-text-field>
                   
                    <v-text-field
                        outlined
                        label="Fecha Inicial"
                        type="datetime-local"
                        v-model="formData.Fecha"
                    >
                        <template v-slot:prepend-inner>
                            <v-checkbox
                                v-model="formData.useFecha"
                                style="margin:0;padding:0"
                            />
                        </template>
                    </v-text-field>
                    <v-text-field
                        outlined
                        label="Fecha Finalizado"
                        type="datetime-local"
                        v-model="formData.Fecha_Finalizado"
                    >
                        <template v-slot:prepend-inner>
                            <v-checkbox
                                v-model="formData.useFecha_Finalizado"
                                style="margin:0;padding:0"
                            />
                        </template>
                    </v-text-field>
                    <v-checkbox label="Agendar" class="my-n5" v-model="formData.Agendar" :true-value="1" :false-value="0" />
                    <v-file-input
                        label="Thumbnail"
                        @change="(e) => preview(e)"
                    />
                    <img style="width:160px;" :src="formData.Foto" alt="" />
                    <v-text-field
                        outlined
                        label="Video Url (Youtube, Facebook)"
                        v-model="formData.Video"
                    ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="
                            $store
                                .dispatch('create_Predica', formData)
                                .then((res) => {
                                    dialog = false;
                                    $store.dispatch('get_Predicas');
                                })
                        "
                    >
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <Footer />
    </div>
</template>

<script>
import moment from "moment";
import Header from "../../components/header/Header";
import BlogThree from "../../components/blog/BlogThree";
import Pagination from "../../components/pagination/Pagination";
import Footer from "../../components/footer/Footer";
// import Quill from "quill";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import blogeditor from "../../components/blogeditor/index.vue";
import Predicas from "../../components/blog/Predicas.vue";
export default {
    components: {
        Header,
        BlogThree,
        Pagination,
        Footer,
        EditorContent,
        blogeditor,
        Predicas,
    },
    data() {
        return {
            logo: require("../../assets/images/logo/logo.png"),
            editor: null,
            dialog: false,
            formData: {
                useFecha: true,
                Fecha: moment().format("yyyy-MM-DDThh:mm"),
                useFecha_Finalizado: true,
                Fecha_Finalizado: moment().format("yyyy-MM-DDThh:mm"),
                Nombre: "",
                Video: "",
                Foto: "",
                Agendar: 0
            },
        };
    },
    watch: {
        "$store.state.iglesia"() {
            this.$store.dispatch("get_Predicas");
        },
    },
    created() {
        this.$store.dispatch("get_Predicas");
        // this.editor = new Editor({
        //     content: "<p>I’m running tiptap with Vue.js. 🎉</p>",
        //     extensions: [StarterKit],
        // });
    },

    methods: {
        preview(e) {
            var reader = new FileReader();
            reader.addEventListener("load", (readFile) => {
                console.log(reader.result);
                this.formData.Foto = reader.result;
            });
            reader.readAsDataURL(e);
        },
        OpenDialog(){
            
            this.formData = {
                useFecha: true,
                Fecha: moment().format("yyyy-MM-DDThh:mm"),
                useFecha_Finalizado: true,
                Fecha_Finalizado: moment().format("yyyy-MM-DDThh:mm"),
                Nombre: "",
                Video: "",
                Foto: "",
                Agendar: 0
            }
            this.dialog = true
        }

    },
};
</script>
