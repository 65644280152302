import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import moment from "moment";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
    reducer: (state) => ({
        iglesia: state.iglesia,
        pricingPlan: state.pricingPlan,
        pricinPago: state.pricinPago,
        TransID: state.TransID,
        Email: state.Email,
        admin: state.admin
    }),
});

import {
    GetExtension,
    AddRoute,
    AddSubRoute,
    ChangeFabIconAndName,
    ChangeProperty,
} from "../helpers/helpers";
import router from "../router/index";
import Blog from "../views/blog/Blog.vue";
import Ministerios from "../views/blog/Ministerios.vue";
import Predicas from "../views/predicas/Blog.vue";
import Predica from "../views/predicas/BlogDetails.vue";
import Articulo from "../views/blog/BlogDetails.vue";
import Ministerio from "../views/blog/MinisterioDetails.vue";
Vue.use(Vuex);

const api = "http://74.213.107.126:93/Church/public/api/";

const creditApi =
    "https://www.myvirtualmerchant.com/VirtualMerchant/process.do";

const media = [
    "iglesia_logo",
    "SLIDE_1_ICON",
    "SLIDE_2_ICON",
    "SLIDE_3_ICON",
    "Peticion_Foto",
    "Video",
    "Foto_Conocenos1",
    "Donacion_Foto",
];

const store = new Vuex.Store({
    state: {
        counterEnded: false,
        editorHtml: "",
        lastPredica: null,
        layout: "",
        loading: true,
        admin: {
            Admin: "0",
            Email: "",
            Password: "",
            IglesiaID: "",
        },
        servicios: [],
        menu: [],
        menuAdmin: [],
        iglesia: Object,
        blogContent: [],
        articulos: [],
        predicas: [],
        ministerios: [],
        miembros: [],
        motivos_donacion: [],
        pricingPlan: "",
        pricinPago: "",
        pricinPagoCopy: "",
        creditpayment: null,
        TransID: "",
        Email: "",
    },
    mutations: {
        set_Layout(state, data) {
            state.layout = data;
        },
        set_Servicios(state, data) {
            state.servicios = data;
        },
        set_Iglesia(state, data) {
            // state.TransID = "871";
            // state.Email = "prjesus12@gmail.com";
            // console.log(router.options.routes[0].children[10].path = data.Nav_5)
            // router.options.routes[0].children[10].path = data.Nav_5;
            AddRoute(data, Predicas, "Nav_4");
            AddRoute(data, Ministerios, "Nav_5");
            AddRoute(data, Blog, "Nav_6");
            AddSubRoute(data, Predica, data.Nav_4 + "/:EventoID");
            AddSubRoute(data, Articulo, data.Nav_6 + "/:ArticuloID");
            AddSubRoute(data, Ministerio, data.Nav_5 + "/:MinisterioID");
            try {
                // let pColor = JSON.parse(data.PrimaryColor).rgba;
                // let sColor = JSON.parse(data.SecondaryColor).rgba;
                // let fColor = JSON.parse(data.FooterColor).rgba;

                ChangeFabIconAndName(data.Logo, data.Nombre);

                ChangeProperty("--color-primary", data.PrimaryColor);

                ChangeProperty("--color-secondary", data.SecondaryColor);

                ChangeProperty("--color-footer", data.FooterColor);
            } catch (e) {}

            console.log(router.options.routes);
            state.loading = false;
            state.iglesia = data;
            state.admin.IglesiaID = data.IglesiaID;
            state.menuAdmin = [
                {
                    title: "Editar Pagina",
                    to: "/" + state.iglesia.Nombre_Id + "/editar",
                },
                {
                    title: "Cambiar Layout",
                    to: "/" + state.iglesia.Nombre_Id + "/layoutpicker",
                },
            ];
            state.menu = [
                {
                    title: data.Nav_1,
                    to: "/" + data.Nombre_Id,
                    hash: "#home",
                },
                {
                    title: data.Nav_2,
                    to: "/" + data.Nombre_Id + "/about",
                    hash: "#about",
                },
                {
                    title: data.Nav_3,
                    to: "/" + data.Nombre_Id + "/contact",
                    hash: "#contact",
                },
                {
                    title: data.Nav_4,
                    to: "/" + data.Nombre_Id + "/" + data.Nav_4,
                    hash: "#portfolio",
                },
                {
                    title: data.Nav_5,
                    to: "/" + data.Nombre_Id + "/" + data.Nav_5,
                    hash: "#team",
                },
                {
                    title: data.Nav_6,
                    to: "/" + data.Nombre_Id + "/" + data.Nav_6,
                    hash: "#blog",
                },
                // {
                //     title: "Donaciones",
                //     to: "/" + data.Nombre_Id + "/" + "donaciones",
                //     hash: "#donaciones",
                //     btn: true,
                // },
            ];
            if (data.Donaciones == true) {
                state.menu.push({
                    title: "Donaciones",
                    to: "/" + data.Nombre_Id + "/" + "donaciones",
                    hash: "#donaciones",
                    btn: true,
                });
            }
            this.dispatch("get_Servicios", data.IglesiaID);
        },
        Login(state, data) {
            state.loading = false;
            state.admin = data;
            window.sessionStorage.setItem("admin", JSON.stringify(data));
            // state.menu.push({
            //     title: "Editar Pagina",
            //     to: "/" + state.iglesia.Nombre_Id + "/editar",

            // });
            // state.menu.push({
            //     title: "Cambiar Layout",
            //     to: "/" + state.iglesia.Nombre_Id + "/layoutpicker",
            // });
        },
        Logout(state) {
            state.admin.Admin = 0;
            state.admin.Email = "";
            state.admin.Password = "";

            state.menu = _.reject(state.menu, function(el) {
                return el.title === "Editar Pagina";
            });

            window.location.reload()
        },
        set_Articulos(state, data) {
            state.loading = false;
            state.articulos = data;
            window.scroll({
                top: 0,
                behavior: "smooth",
            });
        },
        set_Predicas(state, data) {
            state.loading = false;
            state.predicas = data;
            window.scroll({
                top: 0,
                behavior: "smooth",
            });
        },
        set_Ministerios(state, data) {
            state.ministerios = data;
        },
        set_Miembros(state, data) {
            state.miembros = data;
        },
        set_Last_Predica(state, data) {
            console.log(data);
            const { Fecha } = data;

            let now = moment();
            if (now > moment(Fecha)) {
                state.counterEnded = true;
            } else {
                state.counterEnded = false;
            }
            state.lastPredica = data;
        },
        set_Motivos_Donacion(state, data) {
            state.motivos_donacion = data;
        },
        set_Pricing(state, data) {
            state.pricingPlan = data.id;
            state.pricinPago = data.pricinPago;
            state.pricinPagoCopy = data.pricinPago;
        },
        set_creditpayment(state, data) {
            state.creditpayment = data;
            let params = "";
            Object.entries(data).forEach(([key, value]) => {
                console.log(key + " " + value); // "a 5", "b 7", "c 9"
                params += "&" + key + "=" + value;
            });

            window.open(
                `http://abcchurchs.com/creditpayment.php?e=1${params}`,
                "_self"
            );
            // router.push({ name: "CreditPayment" });
        },
        clear_TransID(state, data) {
            state.TransID = "";
            alert("Pago Realizado, Favor verificar su Email");
        },
    },
    actions: {
        get_Servicios({ commit }, IglesiaID) {
            axios
                .get(api + "servicios", { params: { IglesiaID: IglesiaID } })
                .then((response) => {
                    console.log(response.data);
                    commit("set_Servicios", response.data);
                });
        },

        update_Servicio({ commit, state }, data) {
            let formData = new FormData();
            let keys = Object.keys(data);

            axios
                .post(api + "update-servicio", {
                    IglesiaID: state.iglesia.IglesiaID,
                    ...data,
                    ICON_ext: GetExtension(data.ICON),
                })
                .then((response) => {
                    console.log(response.data);
                    // commit("set_Servicios", response.data);
                });
        },

        get_Iglesia({ commit, state }, Nombre_Id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(api + "getIglesia", {
                        params: { Nombre_Id: Nombre_Id },
                        timeout: 10000,
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.length > 0) {
                            commit("set_Iglesia", response.data[0]);
                            resolve(response.data);
                        } else {
                            console.log(router.app.$route.path);
                            state.loading = false;
                            if (router.app.$route.path != "/pago") {
                                router.push({ name: "PricingPlan" });
                            } else {
                                router.push({ name: "Pago" });
                            }
                            // router.push('/pricingplan')
                            console.log(router);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        state.loading = false;
                        // router.push("/Error");
                    });
            });
        },

        create_iglesia({ commit, state }, data) {
            let formData = new FormData();
            let keys = Object.keys(data);

            keys.forEach((key) => {
                // console.log(key, data[key]);
                if (
                    key == "PrimaryColor" ||
                    key == "SecondaryColor" ||
                    key == "FooterColor"
                ) {
                    formData.append(key, data[key]);
                } else {
                    formData.append(key, data[key]);
                    if (media.includes(key)) {
                        formData.append(key + "_ext", GetExtension(data[key]));
                    }
                }
            });
            console.log(data);
            formData.append("pricingPlan", state.pricingPlan);
            formData.append("Fecha_Pago", moment().format("MM/DD/YYYY"));
            formData.append(
                "Fecha_Vencimiento",
                moment()
                    .add("month", 1)
                    .format("MM/DD/YYYY")
            );
            return new Promise((resolve, reject) => {
                axios.post(api + "create-page2", formData).then((response) => {
                    console.log(response);
                    commit("set_Iglesia", response.data);
                    resolve(response.data);
                });
            });
        },

        update_iglesia({ commit, state }, data) {
            state.loading = true;
            console.log(data);
            let formData = new FormData();
            let keys = Object.keys(data);

            keys.forEach((key) => {
                formData.append(key, data[key]);
                console.log(data[key]);
                if (media.includes(key)) {
                    console.log(key + "_ext" + GetExtension(data[key]));
                    formData.append(key + "_ext", GetExtension(data[key]));
                }
            });

            formData.append("IglesiaID", state.iglesia.IglesiaID);
            axios
                .post(api + "edit-page", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    console.log(response);
                    state.loading = false;
                    state.iglesia = response.data;
                })
                .catch((e) => {
                    state.loading = false;
                });
        },

        update_iglesiaFull({ commit, state }, data) {
            state.loading = false;
            let formData = new FormData();
            let keys = Object.keys(data);

            keys.forEach((key) => {
                // console.log(key, data[key]);
                formData.append(key, data[key]);
                if (media.includes(key)) {
                    formData.append(key + "_ext", GetExtension(data[key]));
                }
            });
            formData.append("IglesiaID", state.iglesia.IglesiaID);
            // console.log(formData);
            axios.post(api + "edit-pageFull", formData).then((response) => {
                console.log(response);
                // state.iglesia = response.data;
                state.loading = false;
            });
        },

        update_layout({ commit, state }, data) {
            state.loading = false;
            return new Promise((resolve, reject) => {
                axios
                    .post(api + "update_layout", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        // state.iglesia = response.data;
                        resolve(response.data);
                        state.loading = false;
                    });
            });
        },

        Login({ commit, state }) {
            state.loading = true;
            return new Promise((resolve, reject) => {
                axios.post(api + "Login", state.admin).then((response) => {
                    console.log(response);
                    if (response.data != "") {
                        commit("Login", response.data);
                        resolve(response.data);
                    } else {
                        state.loading = false;
                        resolve("Error de Login");
                    }
                });
            });
        },

        Logout({ commit }) {
            commit("Logout");
        },

        get_Articulo({ commit, state, dispatch }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;

                axios
                    .get(api + "articulo", {
                        params: {
                            IglesiaID: state.iglesia.IglesiaID,
                            ArticuloID: data.ArticuloID,
                        },
                    })
                    .then((response) => {
                        //console.log(response.data);
                        // commit("useEditor", response.data.HTML);
                        state.editorHtml = JSON.parse(response.data.HTML);
                        resolve(response.data);
                        state.loading = false;
                    });
            });
        },

        get_Articulos({ commit, state }, page) {
            state.loading = true;
            axios
                .get(api + "articulos?page=" + page, {
                    params: { IglesiaID: state.iglesia.IglesiaID },
                })
                .then((response) => {
                    console.log(response.data);
                    commit("set_Articulos", response.data);
                });
        },

        create_Articulo({ state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(api + "articulo-create2", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                        HTML: JSON.stringify(state.editorHtml),
                    })
                    .then((response) => {
                        console.log(response);
                        // state.editor.destroy();
                        window.location.reload();
                        resolve("Ok");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        update_Articulo({ state }, data) {
            console.log(state.editorHtml);
            return new Promise((resolve, reject) => {
                axios
                    .post(api + "articulo-update2", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                        HTML: JSON.stringify(state.editorHtml),
                    })
                    .then((response) => {
                        console.log(response.data);
                        window.location.reload();
                        resolve("Ok");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        delete_Articulo({ state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(api + "articulo-delete", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        window.location.reload();
                        resolve("Ok");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        create_peticion({ state }, data) {
            return new Promise((resolve, reject) => {
                // state.loading = true;
                axios
                    .post(api + "peticion-store", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        // window.location.reload();
                        resolve("Ok");
                        // state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        get_Predicas({ commit, state }, page) {
            state.loading = true;
            axios
                .get(api + "eventos?page=" + page, {
                    params: { IglesiaID: state.iglesia.IglesiaID },
                })
                .then((response) => {
                    console.log(response.data);
                    commit("set_Predicas", response.data);
                });
        },

        get_Predica({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .get(api + "evento", {
                        params: {
                            IglesiaID: state.iglesia.IglesiaID,
                            EventoID: data.EventoID,
                        },
                    })
                    .then((response) => {
                        // console.log(response.data);
                        resolve(response.data);
                        state.loading = false;
                        // commit("set_Predicas", response.data);
                    });
            });
        },

        get_Last_Predica({ commit, state }) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .get(api + "eventoLast", {
                        params: {
                            IglesiaID: state.iglesia.IglesiaID,
                            Fecha: moment().format("MM/DD/YYYY"),
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        resolve(response.data);
                        state.loading = false;
                        if (response.data instanceof Object)
                            commit("set_Last_Predica", response.data);
                    })
                    .catch((e) => {
                        state.loading = false;
                    });
            });
        },

        create_Predica({ state }, data) {
            return new Promise((resolve, reject) => {
                // state.loading = true;
                axios
                    .post(api + "evento-store", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                        Fecha: moment(data.Fecha).format("MM/DD/YYYY hh:mm a"),
                        Fecha_Finalizado: moment(data.Fecha_Finalizado).format(
                            "MM/DD/YYYY hh:mm a"
                        ),
                        Foto_ext: GetExtension(data.Foto),
                    })
                    .then((response) => {
                        console.log(response);
                        window.location.reload();
                        resolve("Ok");
                        // state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        delete_Predica({ state }, data) {
            return new Promise((resolve, reject) => {
                // state.loading = true;
                axios
                    .post(api + "evento-delete", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        resolve("Ok");
                        // state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        update_Predica({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;

                axios
                    .post(api + "evento-update", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                        Fecha: moment(data.Fecha).format("MM/DD/YYYY hh:mm a"),
                        Fecha_Finalizado: moment(data.Fecha_Finalizado).format(
                            "MM/DD/YYYY hh:mm a"
                        ),
                        Foto_ext: GetExtension(data.Foto),
                    })
                    .then((response) => {
                        console.log(response);
                        resolve("Ok");
                        window.location.reload();
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        create_Ministerio({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "ministerio-store2", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                        Lema: JSON.stringify(state.editorHtml),
                    })
                    .then((response) => {
                        console.log(response);
                        //state.editor.destroy();
                        window.location.reload();
                        resolve("Ok");
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        state.loading = false;
                        // state.loading = false;
                    });
            });
        },

        update_Ministerio({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "ministerio-update", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                        Lema: JSON.stringify(state.editorHtml),
                    })
                    .then((response) => {
                        console.log(response);
                        //state.editor.destroy();
                        window.location.reload();
                        resolve("Ok");
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        state.loading = false;
                        // state.loading = false;
                    });
            });
        },

        delete_Ministerio({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "ministerio-delete", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        //state.editor.destroy();
                        resolve("Ok");
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        state.loading = false;
                        // state.loading = false;
                    });
            });
        },

        get_Ministerios({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .get(api + "ministerios", {
                        params: {
                            IglesiaID: state.iglesia.IglesiaID,
                        },
                    })
                    .then((response) => {
                        // console.log(response.data);
                        commit("set_Ministerios", response.data);
                        resolve(response.data);
                        state.loading = false;
                        // commit("set_Predicas", response.data);
                    });
            });
        },

        get_Ministerio({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .get(api + "ministerio", {
                        params: {
                            IglesiaID: state.iglesia.IglesiaID,
                            MinisterioID: data.MinisterioID,
                        },
                    })
                    .then((response) => {
                        // console.log(response.data);

                        resolve(response.data);
                        state.loading = false;
                        // commit("set_Predicas", response.data);
                    });
            });
        },

        create_Miembro({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "directiva-create", {
                        IglesiaID: state.iglesia.IglesiaID,
                        // MinisterioID: data.MinisterioID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        //state.editor.destroy();
                        resolve("Ok");
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        state.loading = false;
                        // state.loading = false;
                    });
            });
        },

        get_Miembros({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .get(api + "directiva", {
                        params: {
                            IglesiaID: state.iglesia.IglesiaID,
                            MinisterioID: data.MinisterioID,
                        },
                    })
                    .then((response) => {
                        // console.log(response.data);

                        resolve(response.data);
                        state.loading = false;
                        commit("set_Miembros", response.data);
                    });
            });
        },

        delete_Miembro({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "directiva-delete", {
                        IglesiaID: state.iglesia.IglesiaID,
                        // MinisterioID: data.MinisterioID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        //state.editor.destroy();
                        resolve("Ok");
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        state.loading = false;
                        // state.loading = false;
                    });
            });
        },

        verify_IglesiaNombre({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "verifyName", data)
                    .then((response) => {
                        //console.log(response);
                        //state.editor.destroy();
                        resolve(response.data);
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        state.loading = false;
                        // state.loading = false;
                    });
            });
        },

        get_Iglesias({ commit, state }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(api + "iglesias")
                    .then((response) => {
                        console.log(response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        state.loading = false;
                    });
            });
        },

        set_Credenciales({ state }, data) {
            return new Promise((resolve, reject) => {
                // state.loading = true;
                axios
                    .post(api + "Credenciales", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        resolve("Ok");
                        // state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        post_Donacion({ state }, data) {
            return new Promise((resolve, reject) => {
                // state.loading = true;
                axios
                    .post(api + "Donacion", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        resolve("Ok");
                        // state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        get_Donaciones({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get(api + "Donaciones", {
                        params: {
                            IglesiaID: state.iglesia.IglesiaID,
                            ...data,
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        state.loading = false;
                    });
            });
        },

        get_Motivos_Donacion({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get(api + "Motivos_Donacion", {
                        params: {
                            IglesiaID: state.iglesia.IglesiaID,
                            ...data,
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        commit("set_Motivos_Donacion", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        state.loading = false;
                    });
            });
        },

        post_borrar_Motivos_Donacion({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "borrar-motivo-donacion", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        resolve("Ok");
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        post_insertar_Motivos_Donacion({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "insertar-motivo-donacion", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        resolve("Ok");
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        post_update_Motivos_Donacion({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true;
                axios
                    .post(api + "update-motivo-donacion", {
                        IglesiaID: state.iglesia.IglesiaID,
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        resolve("Ok");
                        state.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                        // state.loading = false;
                    });
            });
        },

        get_Paquetes({ commit, state }) {
            state.loading = true;
            return new Promise((resolve, reject) => {
                axios
                    .get(api + "paquetes")
                    .then((response) => {
                        console.log(response.data);

                        resolve(response.data);
                    })
                    .catch((e) => {
                        state.loading = false;
                    });
            });
        },

        Pago({ commit, state }, data) {
            state.loading = true;
            return new Promise((resolve, reject) => {
                axios
                    .post(api + "Pago", {
                        ...data,
                        ...state.iglesia,
                        Fecha_Pago: moment().format("MM/DD/YYYY"),
                        pricinPago: state.pricinPago,
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data != "") {
                            // commit("Login", response.data);
                            resolve(response.data);
                        } else {
                            state.loading = false;
                            resolve("Error de Login");
                        }
                    });
            });
        },

        GeneratePromo({ commit, state }, data) {
            state.loading = true;
            return new Promise((resolve, reject) => {
                axios
                    .post(api + "GeneratePromo", {
                        IglesiaID: state.iglesia.IglesiaID,
                        Nombre_Iglesia: state.iglesia.Nombre,
                        Fecha: moment().format("MM/DD/YYYY"),
                        pricinPago: state.pricinPago,
                        Fecha_Expiracion: moment()
                            .add("M", 1)
                            .format("MM/DD/YYYY"),
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data != "") {
                            // commit("Login", response.data);
                            alert("Verifique su Email");
                            resolve(response.data);
                        } else {
                            state.loading = false;
                            resolve("Error de Login");
                        }
                    });
            });
        },

        GetPromo({ commit, state }, data) {
            state.loading = true;
            return new Promise((resolve, reject) => {
                axios
                    .post(api + "GetPromo", {
                        IglesiaID: state.iglesia.IglesiaID,
                        Fecha: moment().format("MM/DD/YYYY"),
                        Fecha_Expiracion: moment()
                            .add("M", 1)
                            .format("MM/DD/YYYY"),
                        ...data,
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data != "") {
                            // commit("Login", response.data);
                            resolve(response.data);
                        } else {
                            state.loading = false;
                            resolve(0);
                        }
                    });
            });
        },

        PagoCredito({ commit, state, dispatch }, data) {
            state.loading = true;
            return new Promise((resolve, reject) => {
                state.Email = data.Email;
                axios
                    .post(api + "PagoCredito", {
                        ...data,
                        ...state.iglesia,
                        Fecha_Pago: moment().format("MM/DD/YYYY"),
                        Fecha_Expiracion: moment().add("M", 1).format("MM/DD/YYYY"),
                        pago: state.pricinPago,
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data != "") {
                            // commit("Login", response.data);
                            dispatch("creditpayment", response.data);
                            resolve(response.data);
                        } else {
                            state.loading = false;
                            resolve("Error de Login");
                        }
                    });
            });
        },

        creditpayment({ commit, state }, data) {
            state.loading = true;
            // window.open("https://www.abcsoftwarepr.com/payment/creditpayment_APP.aspx?id=" + data, "_tab" );
            return new Promise((resolve, reject) => {
                axios.post(api + "creditpayment/" + data).then((response) => {
                    console.log(response.data);
                    if (response.data != "") {
                        state.TransID = data;
                        commit("set_creditpayment", {
                            ApiUrl: response.data.ApiUrl,
                            ssl_merchant_id: response.data.MerchantID,
                            ssl_user_id: response.data.UserID,
                            ssl_pin: response.data.PinID,
                            ssl_transaction_type: response.data.TransactionType,
                            ssl_show_form: false,
                            ssl_receipt_apprvl_method: "REDG",
                            ssl_receipt_apprvl_get_url:
                                response.data.ResponseOnApproval,
                            ssl_receipt_decl_method:
                                response.data.TransactionMethod,
                            ssl_receipt_decl_get_url:
                                response.data.ResponseOnDecline,
                            ssl_error_url: response.data.ResponseOnError,
                            ssl_invoice_number: response.data.InvoiceID,
                            ssl_card_present: "N",
                            ssl_cvv2cvc2_indicator: "1",
                            ssl_card_number: response.data.CardNumber,
                            ssl_exp_date: response.data.ExpirationDate,
                            ssl_cvv2cvc2: response.data.CardSecurityCode,
                            ssl_amount: response.data.Amount,
                            ssl_salestax: response.data.Tax,
                            ssl_customer_code: data,
                        });

                        resolve(response.data);
                    } else {
                        state.loading = false;
                        resolve("Error de Login");
                    }
                });
            });
        },

        creditpaymentPost({ commit, state }, data) {
            state.loading = true;
            return new Promise((resolve, reject) => {
                axios
                    .post(data.ApiUrl, {
                        ssl_merchant_id: data.MerchantID,
                        ssl_user_id: data.UserID,
                        ssl_pin: data.PinID,
                        ssl_transaction_type: data.TransactionType,
                        ssl_show_form: false,
                        ssl_receipt_apprvl_method: "REDG",
                        ssl_receipt_apprvl_get_url: data.ResponseOnApproval,
                        ssl_receipt_decl_method: data.TransactionMethod,
                        ssl_receipt_decl_get_url: data.ResponseOnDecline,
                        ssl_error_url: data.ResponseOnError,
                        ssl_invoice_number: data.InvoiceID,
                        ssl_card_present: "N",
                        ssl_cvv2cvc2_indicator: "1",
                        ssl_card_number: data.CardNumber,
                        ssl_exp_date: data.ExpirationDate,
                        ssl_cvv2cvc2: data.CardSecurityCode,
                        ssl_amount: data.Amount,
                        ssl_salestax: data.Tax,
                    })
                    .then((result) => {
                        console.log(result);
                    });
            });
        },

        SaveCredit({ commit, state, dispatch }, data) {
            state.loading = true;
            return new Promise((resolve, reject) => {
                axios
                    .post(api + "SaveCredit", {
                        ...state.iglesia,
                        Fecha: moment().format("MM/DD/YYYY"),
                        Pago: state.pricinPago,
                        TransID: state.TransID,
                        Email: state.Email,
                    })
                    .then((response) => {
                        state.loading = false;
                        console.log(response.data);
                        if (response.data != "") {
                            // commit("Login", response.data);
                            // dispatch("creditpayment", response.data);
                            resolve(response.data);
                        } else {
                            state.loading = false;
                            resolve("Error de Login");
                        }
                    });
            });
        },
    },
    plugins: [vuexLocal.plugin],
});

export default store;
