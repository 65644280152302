import moment from "moment";
import router from "../router/index";
export function GetExtension(data) {
    if (data) {
        if (data.includes("png")) {
            return ".png";
        } else if (data.includes("jpg")) {
            return ".jpg";
        } else if (data.includes("gif")) {
            return ".gif";
        } else if (data.includes("jpeg")) {
            return ".jpeg";
        } else if (data.includes("svg")) {
            return ".svg";
        } else if (data.includes("mp4")) {
            return ".mp4";
        } else if (data.includes("avi")) {
            return ".avi";
        } else if (data.includes("mkv")) {
            return ".mkv";
        }
    }

    return "png";
}

export function AddRoute(data, importing, variable) {
    router.addRoute({
        path: "/:Nombre_Id/" + data[variable],
        name: data[variable],
        meta: {
            title: "",
        },
        component: importing,
    });
}

export function AddSubRoute(data, importing, variable) {
    router.addRoute({
        path: "/:Nombre_Id/" + variable,
        name: data[variable],
        meta: {
            title: "",
        },
        component: importing,
    });
}

export function ChangeProperty(stringVar, value) {
    let root = document.documentElement;
    if (value != "") {
        root.style.setProperty(stringVar, value);
    }
}

export function ChangeFabIconAndName(Logo, Nombre) {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
    }

    link.href = Logo;
    window.title = Nombre;
    document.title = Nombre;
}

export function IsFull(value){
    if(value != '' && value != null && value != 'null' ){
        return true;
    }
    return false;
}

export function MMddYYYY(value){
    if(value){
        return moment(value).format("MM/DD/YYYY")
    }
    return "Fecha no disponible"
    
}
