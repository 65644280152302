<template>
    <div>
        <!-- Start Header Area -->
        <Header v-if="loading == false">
            <img slot="logo" :src="logo" />
        </Header>
        <!-- End Header Area -->
        <!-- Start 404 Page  -->
        <div class="error-page-inner bg_color--4" v-if="loading == false">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="inner">
                            <h1 class="heading-title theme-gradient">404!</h1>
                            <h3 class="sub-title">Page not found</h3>
                            <span
                                >The page you were looking for could not be
                                found.</span
                            >
                            <div class="error-button">
                                <router-link class="btn-default" to="/"
                                    >Back To Homepage</router-link
                                >
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- End 404 Page  -->
        <FooterTwo v-if="loading == false" />
        <loader
            v-if="loading == true"
            object="#242424"
            color1="#ffffff"
            color2="#242424"
            size="5"
            speed="1"
            bg="#343a40"
            objectbg="#999793"
            opacity="80"
            name="circular"
        ></loader>
    </div>
</template>

<script>
import Header from "../components/header/Header";
import FooterTwo from "../components/footer/FooterTwo";
export default {
    components: {
        Header,
        FooterTwo,
    },
    data() {
        return {
            loading: true,
            logo: require("../assets/images/logo/logo.png"),
        };
    },
    created() {
        this.CheckPage();
    },
    methods: {
        CheckPage() {
            let { hash } = window.location;
            console.log(hash);

            let split = hash.split("/");

            console.log(split);

            this.$store.dispatch("get_Iglesia", split[1]).then((res) => {
                    this.loading = false;
                });

            // if (split[1] == "iglesia") {
            //     console.log("si");

            //     this.$store.dispatch("get_Iglesia", split[2]).then((res) => {
            //         this.loading = false;
            //     });
            // } else {
            //     this.loading = false;
            // }
        },
    },
};
</script>
