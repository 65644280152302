<template>
    <v-row class="mt_dec--30">
        <!-- Start Blog Area  -->
        <v-col
            lg="4"
            md="4"
            sm="6"
            cols="12"
            v-for="(item, i) in $store.state.ministerios.data"
            :key="i"
        >
            <v-card class="my-box relative">
                <EditorMinisterios mode="edit" :ministerio="item" />
                <img
                    :src="item.Foto"
                    alt=""
                    @click="
                        $router.push(
                            `/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_5}/${item.MinisterioID}`
                        )
                    "
                />
                <div class="content">
                    <router-link
                        :to="
                            `/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_5}/${item.MinisterioID}`
                        "
                        >{{ item.Nombre_Ministerio }}</router-link
                    >
                </div>
            </v-card>
            <!-- <div class="my-box  mt--30 relative">
                <EditorMinisterios mode="edit" :ministerio="item" />

                <img class="thumbnail" :src="item.Foto" alt="Blog Images" />

                <div class="content">
                    <div class="inner">
                        <div class="content_heading">
                            <div class="category_list">
                                <router-link
                                    :to="
                                        `/iglesia/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_5}/${item.MinisterioID}`
                                    "
                                    >{{ item.Autor }}</router-link
                                >
                            </div>
                            <h4 class="heading-title">
                                <router-link
                                    :to="
                                        `/iglesia/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_5}/${item.MinisterioID}`
                                    "
                                    >{{ item.Nombre_Ministerio }}</router-link
                                >
                            </h4>
                        </div>
                        <div class="content_footer">
                            <router-link
                                :to="
                                    `/iglesia/${$route.params.Nombre_Id}/${$store.state.iglesia.Nav_5}/${item.MinisterioID}`
                                "
                                class="rn-btn btn-opacity"
                            >
                                Ver mas
                            </router-link>
                        </div>
                    </div>
                    
                </div>
            </div> -->
        </v-col>
        <!-- End Blog Area  -->
    </v-row>
</template>

<script>
import blogedit from "../blogeditoredit/index.vue";
import EditorMinisterios from "../editores/EditorMinisterios.vue";
export default {
    components: {
        blogedit,
        EditorMinisterios,
    },
    data() {
        return {};
    },
    created() {
        this.$store.dispatch("get_Ministerios");
    },
    watch: {
        "$store.state.iglesia"() {
            this.$store.dispatch("get_Ministerios");
        },
    },
};
</script>

<style lang="scss" scoped>
.my-box {
    position: relative;
    transition: 0.5s;
    img {
        width: 100%;
    }
    .content {
        text-align: center;
        font-size: 24px;
        height: 100%;
    }

    &:hover {
        transform: scale(1.1);
        z-index: 100;
    }
}
</style>
