<template>
    <!-- <ul class="page-list">
    <li class="active"><a href="#">1</a></li>
    <li><a href="#">2</a></li>
    <li><a href="#">3</a></li>
    <li><a href="#">4</a></li>
    <li>
      <a href="#"><i class="fas fa-angle-right"></i></a>
    </li>
  </ul> -->
    <span>
        <v-pagination v-if="total >0" v-model="page" class="my-4" :length="total"></v-pagination>
    </span>
</template>

<script>
export default {
    props: {
        total: {
            type: [String, Number],
            default: 1,
        },
        store: {
            type: [String],
        },
    },
    watch: {
        page() {
            this.$store.dispatch(this.store, this.page);
        },
    },
    data() {
        return {
            page: 1,
        };
    },
};
</script>
