import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";
import Demo from "../views/Demo.vue";
import studio_agency from "../views/all-home-version/StudioAgency.vue";
Vue.use(VueRouter);
import store from "../store/index.js";
// const Layout = () => import('../views/all-home-version/StudioAgency.vue')

const routes = [
    // {
    //     path: "/",
    //     component: Demo,
    //     meta: {
    //         title: "Imroz - VueJS Creative Agency and Portfolio Template",
    //     },
    // },
    {
        path: "/pricingplan",
        name: "PricingPlan",
        meta: {
            title:
                "Pricing Plan - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/PricingPlan.vue"),
    },
    {
        path: "/:Nombre_Id/",
        component: () => import("../layouts/MainLayout.vue"),
        children: [
            {
                path: "layoutpicker",
                name: "layoutpicker",
                meta: {
                    title: "Layout picker",
                },
                component: () => import("../views/LayoutPicker.vue"),
            },
            {
                path: "main",
                name: "StudioAgency",
                meta: {
                    title: "",
                },
                component: () =>
                    import("../views/all-home-version/StudioAgency.vue"),
            },
            {
                path: "",
                name: "",
                meta: {
                    title: "",
                },
                component: () => import("../views/all-home-version/Main.vue"),
            },
            {
                path: "about",
                name: "About",
                meta: {
                    title: "",
                },
                component: () => import("../views/About.vue"),
            },
            {
                path: "articulos",
                name: "Blog",
                meta: {
                    title: "",
                },
                component: () => import("../views/blog/Blog.vue"),
            },
            {
                path: "predicas",
                name: "Predicas",
                meta: {
                    title: "",
                },
                component: () => import("../views/predicas/Blog.vue"),
            },
            {
                path: "blog-details/:ArticuloID?",
                name: "",
                meta: {
                    title: "",
                },
                component: () => import("../views/blog/BlogDetails.vue"),
            },
            {
                path: "predica/:EventoID?",
                name: "",
                meta: {
                    title: "",
                },
                component: () => import("../views/predicas/BlogDetails.vue"),
            },
            {
                path: "contact",
                name: "",
                meta: {
                    title: "",
                },
                component: () => import("../views/Contact.vue"),
            },
            {
                path: "editar",
                name: "",
                meta: {
                    title: "",
                },
                component: () => import("../views/EditarPagina.vue"),
            },
            {
                path: "ministerios",
                name: "Ministerios",
                meta: {
                    title: "",
                },
                component: () => import("../views/blog/Ministerios.vue"),
            },
            {
                path: "ministerio/:MinisterioID",
                name: "Ministerio",
                meta: {
                    title: "",
                },
                component: () => import("../views/blog/MinisterioDetails.vue"),
            },
            {
                path: "donaciones",
                name: "Donaciones",
                meta: {
                    title: "",
                },
                component: () => import("../views/Donaciones.vue"),
            },
        ],
    },

    {
        path: "/",
        component: () => import("../layouts/AdminLayout.vue"),
        children: [
            {
                path: "",
                name: "Init",
                meta: {
                    title: "",
                },
                component: () => import("../views/Initial.vue"),
            },
            {
                path: "pagina",
                name: "Crear",
                meta: {
                    title: "",
                },
                component: () => import("../views/CrearPagina.vue"),
            },
            {
                path: "directorio",
                name: "directorio",
                meta: {
                    title: "",
                },
                component: () => import("../views/Directorio.vue"),
            },
            {
                path: "pago",
                name: "Pago",
                meta: {
                    title: "",
                },
                component: () => import("../views/Pago.vue"),
            },

            {
                path: "testing",
                name: "testing",
                meta: {
                    title: "",
                },
                component: () => import("../views/TestingPass.vue"),
            },

            {
                path: "creditpayment",
                name: "CreditPayment",
                meta: {
                    title: "creditpayment",
                },
                component: () => import("../views/CreditPayment.vue"),
            },
        ],
    },

    {
        path: "/main-demo",
        name: "MainDemo",
        meta: {
            title: "Main Demo - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/all-home-version/MainDemo.vue"),
    },
    {
        path: "/main-demo-dark",
        name: "MainDemoDark",
        meta: {
            title:
                "Main Demo Dark - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/all-home-version/MainDemoDark.vue"),
    },
    {
        path: "/creative-agency",
        name: "CreativeAgency",
        meta: {
            title:
                "Creative Agency - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/all-home-version/CreativeAgency.vue"),
    },
    {
        path: "/digital-agency",
        name: "DigitalAgency",
        meta: {
            title:
                "Digital Agency - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/all-home-version/DigitalAgency.vue"),
    },
    {
        path: "/business",
        name: "Business",
        meta: {
            title: "Business - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/all-home-version/Business.vue"),
    },
    {
        path: "/corporate-business",
        name: "CorporateBusiness",
        meta: {
            title:
                "Corporate Business - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/CorporateBusiness.vue"),
    },
    {
        path: "/startup",
        name: "StartUp",
        meta: {
            title: "Start Up - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/all-home-version/StartUp.vue"),
    },
    {
        path: "/creative-portfolio",
        name: "CreativePortfolio",
        meta: {
            title:
                "Creative Portfolio - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/CreativePortfolio.vue"),
    },
    {
        path: "/minimal-portfolio",
        name: "MinimalPortfolio",
        meta: {
            title:
                "Minimal Portfolio - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/MinimalPortfolio.vue"),
    },
    {
        path: "/landing-home-particle",
        name: "LandingHomeParticle",
        meta: {
            title:
                "Landing Home Particle - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/LandingHomeParticle.vue"),
    },
    {
        path: "/landing-creative-agency",
        name: "LandingCreativeAgency",
        meta: {
            title:
                "Landing Creative Agency - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/LandingCreativeAgency.vue"),
    },
    {
        path: "/landing-interior",
        name: "LandingInterior",
        meta: {
            title:
                "Landing Interior - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/LandingInterior.vue"),
    },
    {
        path: "/landing-personal-portfolio",
        name: "LandingPersonalPortfolio",
        meta: {
            title:
                " Personal Portfolio Landing - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/LandingPersonalPortfolio.vue"),
    },
    {
        path: "/landing-dark-portfolio",
        name: "LandingDarkPortfolio",
        meta: {
            title:
                "Personal Portfolio Landing - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/LandingDarkPortfolio.vue"),
    },
    {
        path: "/personal-portfolio",
        name: "PersonalPortfolio",
        meta: {
            title:
                "Personal Portfolio - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/PersonalPortfolio.vue"),
    },
    {
        path: "/designer-portfolio",
        name: "DesignerPortfolio",
        meta: {
            title:
                "Designer Portfolio - VueJS Creative Agency and Portfolio Template",
        },
        component: () =>
            import("../views/all-home-version/DesignerPortfolio.vue"),
    },
    // {
    //     path: "/iglesia/:Nombre_Id/studio-agency/",
    //     name: "StudioAgency",
    //     meta: {
    //         title:
    //             "Studio Agency - VueJS Creative Agency and Portfolio Template",
    //     },
    //     component: () => import("../views/all-home-version/StudioAgency.vue"),
    // },
    {
        path: "/service",
        name: "Service",
        meta: {
            title: "Service - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/service/Service.vue"),
    },
    {
        path: "/service-details",
        name: "ServiceDetails",
        meta: {
            title:
                "Service Details - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/service/ServiceDetails.vue"),
    },

    {
        path: "/portfolio",
        name: "Portfolio",
        meta: {
            title: "Portfolio - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/portfolio/Portfolio.vue"),
    },
    {
        path: "/portfolio-details",
        name: "PortfolioDetails",
        meta: {
            title:
                "Portfolio Details - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/portfolio/PortfolioDetails.vue"),
    },
    {
        path: "/team",
        name: "Team",
        meta: {
            title: "Team - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Team.vue"),
    },

    {
        path: "/testimonial",
        name: "Testimonial",
        meta: {
            title: "Tesimonial - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Testimonial.vue"),
    },
    {
        path: "/column",
        name: "Column",
        meta: {
            title: "Column - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Column.vue"),
    },
    {
        path: "/contact-form",
        name: "ContactForm",
        meta: {
            title:
                "Contact Form - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/ContactForm.vue"),
    },
    {
        path: "/video-popup",
        name: "VideoPopup",
        meta: {
            title: "Video Popup - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/VideoPopup.vue"),
    },
    {
        path: "/brand",
        name: "Brand",
        meta: {
            title: "Brand - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Brand.vue"),
    },
    {
        path: "/counter",
        name: "Counter",
        meta: {
            title: "Counter - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Counter.vue"),
    },

    {
        path: "/gallery",
        name: "Gallery",
        meta: {
            title: "Gallery - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Gallery.vue"),
    },
    {
        path: "/progressbar",
        name: "Progressbar",
        meta: {
            title: "Progressbar - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Progressbar.vue"),
    },
    {
        path: "/button",
        name: "Button",
        meta: {
            title: "Progressbar - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Button.vue"),
    },
    {
        path: "/office-location",
        name: "Office Locaiton",
        meta: {
            title:
                "Office Location - VueJS Creative Agency and Portfolio Template",
        },
        component: () => import("../views/Map.vue"),
    },

    {
        path: "*",
        component: Missing,
        meta: {
            title: "404 - VueJS Creative Agency and Portfolio Template",
        },
    },
];

const router = new VueRouter({
    mode: "hash",
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
});

router.beforeEach((to, from, next) => {
    document.title = window.title;
    next();
});

// Vue.prototype.$route = router;
export default router;
