import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueParticles from "vue-particles";
import CoolLightBox from "vue-cool-lightbox";
import VueScrollactive from "vue-scrollactive";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import "./assets/scss/main.scss";
import "./plugins/vuetify-masks";

import store from "./store";

// import VueLoading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';

Vue.config.productionTip = false;
Vue.use(VueParticles);
Vue.use(CoolLightBox);
Vue.use(VueScrollactive);

// Vue.use(VueLoading);
import loader from "vue-ui-preloader";

import './registerServiceWorker'
import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;
Vue.use(loader);
new Vue({
    router,
    vuetify,
    store: store,
    render: (h) => h(App),
}).$mount("#app");
