<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" :src="logo" />
        </Header>
        <!-- End Header Area -->

        <!-- Start Breadcrump Area  -->
        <div
            class="rn-page-title-area pt--120 pb--190 bg-fit-cover"
            data-black-overlay="7"
            :style="`background-image: url(${articulo.Foto})`"
        >
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-center blog-single-page-title pt--100">
                            <h2 class="heading-title theme-gradient">
                                {{ articulo.Titulo }}
                            </h2>
                            <ul
                                class="justify-center blog-meta d-flex align-center"
                            >
                                <li
                                    class="d-flex"
                                    v-for="(meta, i) in metaList"
                                    :key="i"
                                >
                                    <span v-html="iconSvg(meta.icon)"></span
                                    >{{ meta.text }}
                                </li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- End Breadcrump Area  -->

        <!-- Start Blog Details Area  -->
        <div class="rn-blog-details pt-12 pb--70 bg_color--1">
            <v-container>
                <div class="editor"></div>
                <editor-content :editor="editor" />
            </v-container>
        </div>

        <Footer />
    </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import moment from "moment";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import feather from "feather-icons";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
export default {
    components: {
        Header,
        Footer,
        ValidationObserver,
        ValidationProvider,
        Editor,
        EditorContent,
    },
    data() {
        return {
            editor: null,
            articulo: Object,
            logo: require("../../assets/images/logo/logo.png"),
            formData: {
                name: "",
                email: "",
                subject: "",
                message: "",
            },
            items: [
                {
                    thumb: require("../../assets/images/blog/bl-big-01.jpg"),
                    src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
                },
            ],
            metaList: [
                {
                    icon: "clock",
                    text: "May 18, 2020",
                },
                {
                    icon: "user",
                    text: "Fatima",
                },
                // {
                //     icon: "message-circle",
                //     text: "15 Comments",
                // },
                // {
                //     icon: "heart",
                //     text: "Like",
                // },
            ],
            index: null,
        };
    },

    created() {
        this.getArticulo();
    },
    beforeDestroy() {
        try {
            this.editor.destroy();
        } catch (e) {}
        // this.provider.destroy();
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
        onSubmit() {
            console.log(this.formData);
        },
        getArticulo() {
            let _this = this;
            // this.articulo = this.$store.state.articulos.data.find(
            //     (item) => item.ArticuloID == this.$route.params.ArticuloID
            // );

            this.$store
                .dispatch("get_Articulo", {
                    ArticuloID: this.$route.params.ArticuloID,
                })
                .then((res) => {
                    this.articulo = res;
                    this.editor = new Editor({
                        editable: false,
                        content: JSON.parse(_this.articulo.HTML),
                        extensions: [
                            StarterKit.configure({
                                history: true,
                            }),
                            Highlight,
                            TaskList,
                            TaskItem,
                            Text,
                            TextAlign.configure({
                                types: ["heading", "paragraph"],
                            }),
                        ],
                    });
                    this.metaList[0].text = moment(
                        this.articulo.updated_at
                    ).format("MM/DD/YYYY");
                    this.metaList[1].text = this.articulo.Titulo;
                    console.log(this.articulo);
                });
        },
    },
};
</script>
