<template>
    <span>
        <v-row>
            <v-col
                cols="12"
                sm="6"
                md="3"
                v-for="(item, i) in servicios"
                :key="i"
            >
                <v-card flat class="hover-card">
                    <v-btn
                        v-if="$store.state.admin.Admin == true"
                        style="position:absolute;right:10px;top:10px;"
                        text
                        icon
                        color="red lighten-2"
                        @click="OpenDialog(i)"
                    >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                    <div class="d-flex justify-center">
                        <img :src="item.ICON" alt="portfolio image" />
                    </div>
                    <v-card-title class="d-flex justify-center" primary-title>
                        {{ item.TITULO }}
                    </v-card-title>
                    <v-card-text class="text-center">
                        {{ item.SUBTITULO }}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Editar
                </v-card-title>

                <v-card-text class="mt-5">
                    <label for="Icon" class="d-flex">
                        <img :src="srv.ICON" alt="" srcset="" />
                        <input
                            type="file"
                            name=""
                            id="Icon"
                            style="display: none"
                            @change="(e) => preview(e)"
                        />
                    </label>
                    <v-text-field
                        name="name"
                        label="Titulo"
                        v-model="srv.TITULO"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        name="name"
                        label="Subtitulo"
                        v-model="srv.SUBTITULO"
                        outlined
                    ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="
                            $store.dispatch('update_Servicio', srv);
                            dialog = false;
                        "
                    >
                        Actualizar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            tab: null,
            index: null,
            srv: Object,
        };
    },
    computed: {
        servicios() {
            return this.$store.state.servicios;
        },
    },
    methods: {
        OpenDialog(index) {
            this.dialog = true;

            this.srv = this.$store.state.servicios[index];
        },
        preview(e) {
            if (e.target.files.length > 0) {
                let _this = this;
                var reader = new FileReader();
                reader.onload = function() {
                    // var output = document.getElementById(id);
                    // output.src = reader.result;

                    _this.srv.ICON = reader.result;
                };
                reader.readAsDataURL(e.target.files[0]);
            } else {
                return;
            }
        },
    },
};
</script>
<style lang="scss">
// .hover-card {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     transition: 200ms;
//     height: 280px;
//     padding-top: 10px;
//     padding-bottom: 10px;
//     img {
//         position: relative;
//         width: 128px;
//     }
    
//     &:hover {
//         box-shadow: 0 50px 80px -10px rgba(0, 0, 0, 0.17);
//         transform: translate3d(0, -5px, 0);
//         background-color: dodgerblue;
//         color: white;
//         .v-card__text {
//             color: white;
//         }
//     }
// }
</style>
