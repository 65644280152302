<template>
    <v-card class="miembro-card">
        <div
            class="d-flex poss_absolute w-100 justify-end"
            v-if="$store.state.admin.Admin == 1"
        >
            <v-btn
                text
                color="black"
                @click="
                    $store.dispatch('delete_Miembro', miembro).then((res) => {
                        $store.dispatch('get_Miembros', {
                            MinisterioID: $route.params.MinisterioID,
                        });
                    })
                "
                >borrar</v-btn
            >
        </div>

        <div class="d-flex justify-center">
            <v-avatar class="miembro-img">
                <img :src="miembro.Foto" />
            </v-avatar>
        </div>
        <div class="media">
            <v-icon  color="black" @click="OpenTab(miembro.Facebook)" v-if="miembro.Facebook">
                mdi-facebook
            </v-icon>
            <v-icon  color="black" @click="OpenTab(miembro.Twitter)" v-if="miembro.Twitter">
                mdi-twitter
            </v-icon>
            <v-icon  color="black" @click="OpenTab(miembro.Instagram)" v-if="miembro.Instagram">
                mdi-instagram
            </v-icon>

            <v-icon  color="black" @click="OpenMail(miembro.Email)">mdi-email</v-icon>

            <v-icon  color="black" @click="OpenPhone(miembro.Telefono)">mdi-phone</v-icon>
        </div>
        <v-card-text class="black--text">
            <div class="Nombre black--text">{{ miembro.Nombre }}</div>
            <div class="Posicion black--text">{{ miembro.Titulo }}</div>
            <div class="Nota black--text">{{ miembro.Frase }}</div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        miembro: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        OpenTab(url) {
            console.log(url);
            window.open(url, "_tab");
        },
        OpenPhone(url) {
            console.log(url);
            window.open(`tel:${url}`, "_tab");
        },
        OpenMail(url) {
            console.log(url);
            window.open(`mailto:${url}`, "_tab");
        },
    },
};
</script>

<style lang="scss">
.miembro-card {
    flex-grow: 1;
    height: 100%;
    // background-color: rgb(199, 16, 199) !important;
    border: 5px solid;
    border-color: var(--color-primary) !important;
    position: relative;
    margin: 5px;
    // border-right: 10px solid black !important;

    .miembro-img {
        margin-top: 16px;
        width: 140px !important;
        height: 140px !important;
        border: 5px solid var(--color-secondary);
        background-color: white;
    }
    .Nombre {
        margin-top: 2px;
        text-align: center;
        font-size: 30px;
        color: white;
        font-weight: bold;
    }
    .Posicion {
        margin-top: 10px;
        text-align: center;
        font-size: 18px;
        color: white;
    }
    .Nota {
        margin-top: 20px;
        text-align: center;
        font-size: 18px;
        color: white;

        font-weight: 100;
    }
    .media {
        // position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        gap: 10px;
    }
    .btn-phone {
        margin: -10px;
        right: 0;
        // box-shadow: none;
        // border-radius: 10px;
        // border-left: 8px solid green !important;
        // border-bottom: 5px solid green !important;
    }

    .back_face {
        display: none;
        background-color: black;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}
</style>
