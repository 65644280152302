<template>
    <div>
        <v-btn v-if="mode == 'create' && $store.state.admin.Admin == 1" color="success" @click="OpenDialog()"
            >Agregar</v-btn
        >
        <v-row v-if="mode == 'edit' && $store.state.admin.Admin == 1" class="w-100 poss_absolute justify-end z-index-1">
            <v-btn color="success" class="mr-2" @click="EditData">Editar</v-btn>
            <v-btn color="red" @click="borrarData()">Borrar</v-btn>
        </v-row>
        <v-dialog v-model="dialog" width="800">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Crear Ministerio
                </v-card-title>

                <v-card-text class="mt-5">
                    <v-file-input
                        label="Foto"
                        @change="(e) => preview(e)"
                        outlined
                    ></v-file-input>
                    <div class="d-flex justify-center mb-5">
                        <v-img max-width="120" :src="formData.Foto" />
                    </div>
                    <v-text-field
                        name="name"
                        label="Titulo"
                        id="id"
                        outlined
                        v-model="formData.Titulo"
                    ></v-text-field>
                    <!-- <v-text-field
                        name="name"
                        label="Lema"
                        id="id"
                        outlined
                        v-model="formData.Lema"
                    ></v-text-field> -->
                    <shareEditor />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false"
                        >Cancelar</v-btn
                    >
                    <v-btn
                        color="primary"
                        text
                        @click="
                            $store
                                .dispatch(
                                    mode == 'create'
                                        ? 'create_Ministerio'
                                        : 'update_Ministerio',
                                    formData
                                )
                                .then(() => {
                                    dialog = false;
                                    $store.dispatch('get_Ministerios');
                                })
                        "
                    >
                        Continuar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Borrar
                </v-card-title>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="
                            $store
                                .dispatch('delete_Ministerio', formData)
                                .then(() => {
                                    dialog2 = false;
                                    $store.dispatch('get_Ministerios');
                                })
                        "
                    >
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Agregar Persobna -->
    </div>
</template>

<script>
import shareEditor from "../shareEditor/index.vue";
export default {
    props: {
        mode: {
            type: String,
            default: "create",
        },
        ministerio: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        shareEditor,
    },
    data() {
        return {
            model: 1,
            dialog: false,
            dialog2: false,
            formData: {
                Titulo: "",
                Foto: "",
            },
        };
    },
    methods: {
        submit() {
            this.items.push({
                avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                ...this.usuario,
            });
        },
        preview(e) {
            try {
                let filereader = new FileReader();
                filereader.onload = () => {
                    console.log(filereader.result);
                    this.formData.Foto = filereader.result;
                };
                filereader.readAsDataURL(e);
            } catch (e) {}

            // console.log(e);
        },
        EditData() {
            console.log(this.ministerio);
            this.formData = {
                MinisterioID: this.ministerio.MinisterioID,
                Titulo: this.ministerio.Nombre_Ministerio,
                Foto: this.ministerio.Foto,
            };
            this.$store.state.editorHtml = JSON.parse(this.ministerio.Lema);
            this.dialog = true;
        },
        borrarData() {
            this.formData = {
                MinisterioID: this.ministerio.MinisterioID,
            };

            this.dialog2 = true;
        },
        OpenDialog(){
             this.formData = {
                MinisterioID: "",
                Titulo: "",
                Foto: "",
            };
            this.dialog = true
        }
    },
};
</script>

<style lang="scss">
.card {
    max-width: 320px;
    border: 5px solid rgb(91, 63, 215, 1) !important;
}
.overlapImage {
    position: absolute;
    top: -70px;
    width: 140px !important;
    height: 140px !important;

    border: 3px solid rgb(91, 63, 215, 1) !important;
    // border-top-style: none !important;
    background: linear-gradient(0, #ffc0cb 56%, #fff 0%);
}
.z-index-1{
    z-index: 10;
}
</style>
